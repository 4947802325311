import { initCustomComputeWebSocketListeners } from '../features/customcompute/webSocketListeners'
import { initAppMessageWebSocketListeners } from '../features/dashboard/appMessageWsSlice'
import { initNotificationWebSocketListeners } from '../features/notification/notificationWsSlice'
import { initRuntimeWebSocketListeners } from '../features/runtime/runtimeWsSlice'
import { initAnalysisDetailsWebSocketListeners } from '../features/workbench/analysis/analysisDetailsWsSlice'
import { initAnalysisWebSocketListeners } from '../features/workbench/analysis/analysisWsSlice'
import { initComponentsWebSocketListeners } from '../features/workbench/analysis/common/componentsWsSlice'
import { initAnalysisResultsWebSocketListeners } from '../features/workbench/analysis/results/analysisResultsWsSlice'
import { initReadmeWebSocketListeners } from '../features/workbench/readme/readmeWsSlice'
import { initWorkbenchWebSocketListeners } from '../features/workbench/workbenchWsSlice'

// Initialize all WebSocket listeners
initCustomComputeWebSocketListeners()
initAppMessageWebSocketListeners()
initNotificationWebSocketListeners()
initRuntimeWebSocketListeners()
initAnalysisDetailsWebSocketListeners()
initAnalysisWebSocketListeners()
initComponentsWebSocketListeners()
initAnalysisResultsWebSocketListeners()
initReadmeWebSocketListeners()
initWorkbenchWebSocketListeners()
