export interface Config {
    multiRegionSupport: boolean
    supportedRegions: string[]
    creditSystemEnabled: boolean
    guestSystemEnabled: boolean
    signupEnabled: boolean
    platform: 'aws' | 'dnanexus'
}

const config: Config = {
    multiRegionSupport: import.meta.env.VITE_MULTI_REGION_SUPPORT === 'true',
    supportedRegions: import.meta.env.VITE_SUPPORTED_REGIONS?.split(',') || [],
    creditSystemEnabled: import.meta.env.VITE_CREDIT_SYSTEM_ENABLED === 'true',
    guestSystemEnabled: import.meta.env.VITE_GUEST_SYSTEM_ENABLED === 'true',
    signupEnabled: import.meta.env.VITE_SIGNUP_ENABLED === 'true',
    platform: import.meta.env.VITE_PLATFORM,
}

export default config
