import { apiSlice } from '../../../app/apiSlice'
import { PermissionSet, Role, RoleWithUserCount } from '../../../model/model'

type GetRoleListResponse = {
    roleList: Role[]
}

type CreateRoleRequest = {
    name: string
    autoCreateDefault: boolean
    permissionSet: PermissionSet
}

type UpdateRoleRequest = CreateRoleRequest & {
    id: number
}

export const roleApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getRoleList: builder.query<GetRoleListResponse, void>({
            query: () => ({
                url: '/private/role/list',
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.roleList.map(({ id }) => ({ type: 'Role' as const, id })),
                          { type: 'Role', id: 'LIST' },
                      ]
                    : [{ type: 'Role', id: 'LIST' }],
        }),

        listRolesWithUserCount: builder.query<RoleWithUserCount[], void>({
            query: () => ({
                url: '/private/admin/role/list',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Role' as const, id })), { type: 'Role', id: 'LIST' }]
                    : [{ type: 'Role', id: 'LIST' }],
        }),

        createRole: builder.mutation<RoleWithUserCount, CreateRoleRequest>({
            query: (req) => ({
                url: '/private/admin/role/create',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: [{ type: 'Role', id: 'LIST' }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: newRole } = await queryFulfilled
                    dispatch(
                        roleApiSlice.util.updateQueryData('listRolesWithUserCount', undefined, (draft) => {
                            draft.push(newRole)
                        }),
                    )
                } catch (error) {
                    console.error('Error creating role:', error)
                }
            },
        }),

        updateRole: builder.mutation<RoleWithUserCount, UpdateRoleRequest>({
            query: (req) => ({
                url: '/private/admin/role/update',
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) => (result ? [{ type: 'Role', id: result.id }] : [{ type: 'Role', id: 'LIST' }]),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    roleApiSlice.util.updateQueryData('listRolesWithUserCount', undefined, (draft) => {
                        const role = draft.find((r) => r.id === arg.id)
                        if (role) {
                            Object.assign(role, arg)
                        }
                    }),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error updating role:', error)
                    patchResult.undo()
                }
            },
        }),

        deleteRoles: builder.mutation<number[], number[]>({
            query: (req) => ({
                url: '/private/admin/role/delete',
                method: 'DELETE',
                body: req,
            }),
            invalidatesTags: (result) =>
                result
                    ? [...result.map((id) => ({ type: 'Role' as const, id })), { type: 'Role', id: 'LIST' }]
                    : [{ type: 'Role', id: 'LIST' }],
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    roleApiSlice.util.updateQueryData('listRolesWithUserCount', undefined, (draft) => {
                        return draft.filter((role) => !ids.includes(role.id))
                    }),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error deleting roles:', error)
                    patchResult.undo()
                }
            },
        }),
    }),
})

export const {
    useGetRoleListQuery,
    useLazyListRolesWithUserCountQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRolesMutation,
    endpoints: { getRoleList },
} = roleApiSlice
