export type Component =
    | typeof ScRnaSeqPrepareAnalysis
    | typeof ScRnaSeqPrepareAnalysisFromAsset
    | typeof ScRnaSeqQcCellSelection
    | typeof ScRnaSeqQcGeneSelection
    | typeof ScRnaSeqCellTypePrediction
    | typeof ScRnaSeqClustering
    | typeof ScRnaSeqCellComposition
    | typeof ScRnaSeqAnnotateCells
    | typeof ScRnaSeqDoubletDetection
    | typeof PlotGeneExpression
    | typeof PathwayActivityInference
    | typeof PlotPathwayActivityScores
    | typeof ScRnaSeqLoadData
    | typeof Umap
    | typeof AddFilter
    | typeof SegmentData
    | typeof RemoveObservations
    | typeof RemoveSamples
    | typeof DifferentialExpression
    | typeof ImportDifferentialExpression
    | typeof GeneSetEnrichment
    | typeof PlotMarkerGenes
    | typeof WeightedGeneCoExpressionNetworkAnalysis
    | typeof RnaSeqNormalize
    | typeof ScRnaSeqNormalize
    | typeof BulkExpOmicsPrepareAnalysis
    | typeof BulkExpOmicsLoadData
    | typeof BulkExpOmicsLoadCombinedData
    | typeof DifferentialPathwayActivity
    | typeof ComputeStatistics
    | typeof DeleteBarcodes
    | typeof RnaSeqQcGeneSelection
    | typeof NCounterPrepareAnalysis

// Generic commands
export const GeneSetEnrichment = 'GeneSetEnrichment'
export const WeightedGeneCoExpressionNetworkAnalysis = 'WeightedGeneCoExpressionNetworkAnalysis'
export const PlotMarkerGenes = 'PlotMarkerGenes'
export const PlotGeneExpression = 'PlotGeneExpression'
export const DifferentialExpression = 'DifferentialExpression'
export const DifferentialPathwayActivity = 'DifferentialPathwayActivity'
export const RemoveObservations = 'RemoveObservations'
export const RemoveSamples = 'RemoveSamples'
export const AddFilter = 'AddFilter'
export const SegmentData = 'SegmentData'
export const Umap = 'Umap'
export const ImportDifferentialExpression = 'ImportDEG'
export const PathwayActivityInference = 'PathwayActivityInference'
export const PlotPathwayActivityScores = 'PlotPathwayActivityScores'
export const ComputeStatistics = 'ComputeStatistics'
export const DeleteBarcodes = 'DeleteBarcodes'

// ScRNA-seq specific commands
export const ScRnaSeqLoadData = 'ScRnaSeqLoadData'
export const ScRnaSeqPrepareAnalysis = 'ScRnaSeqPrepareAnalysis'
export const ScRnaSeqPrepareAnalysisFromAsset = 'ScRnaSeqPrepareAnalysisFromAsset'
export const ScRnaSeqQcCellSelection = 'ScRnaSeqQcCellSelection'
export const ScRnaSeqQcGeneSelection = 'ScRnaSeqQcGeneSelection'
export const ScRnaSeqNormalize = 'ScRnaSeqNormalize'
export const ScRnaSeqCellTypePrediction = 'ScRnaSeqCellTypePrediction'
export const ScRnaSeqClustering = 'ScRnaSeqClustering'
export const ScRnaSeqCellComposition = 'ScRnaSeqCellComposition'
export const ScRnaSeqAnnotateCells = 'ScRnaSeqAnnotateCells'
export const ScRnaSeqDoubletDetection = 'ScRnaSeqDoubletDetection'

// Bulk specific commands
export const BulkExpOmicsLoadData = 'BulkExpOmicsLoadData'
export const BulkExpOmicsLoadCombinedData = 'BulkExpOmicsLoadCombinedData'
export const BulkExpOmicsPrepareAnalysis = 'BulkExpOmicsPrepareAnalysis'

// RNA-seq specific commands
export const RnaSeqQcGeneSelection = 'RnaSeqQcGeneSelection'
export const RnaSeqNormalize = 'RnaSeqNormalize'

// NCounter specific commands
export const NCounterPrepareAnalysis = 'NCounterPrepareAnalysis'
