import { apiSlice } from '../../../app/apiSlice'
import {
    ExecutionPod,
    ExecutionPodAccess,
    ExecutionPodAccessType,
    ExecutionPodVersion,
    ExecutionPodVersionStatus,
    ExecutionPodVisibility,
    ExecutionPodWithAccess,
    RepositoryTag,
} from '../../../model/customModule'
import { FileObject } from '../../../model/model'
import { GitHubOAuthResult } from '../../oauth/execution-pod/model'
import { PodExecutionDefinitionV1 } from './execution-definition'

type AppletPod = {
    applet: FileObject
    modifiesOmics: boolean
}

type CreateExecutionPodRequest = {
    name: string
    description: string
    tags: string[]
    repositoryUrl: string
    applet: AppletPod | null
    visibility: ExecutionPodVisibility
}

type UpdateExecutionPodRequest = {
    id: string
    name: string
    description: string
    tags: string[]
    visibility: ExecutionPodVisibility
}

type AddUserToExecutionPodRequest = {
    executionPodId: string
    email: string
    accessType: ExecutionPodAccessType
}

type RemoveUserFromExecutionPodRequest = {
    executionPodId: string
    userId: number
}

type UpdateUserAccessRequest = {
    executionPodId: string
    userId: number
    accessType: ExecutionPodAccessType
}

type GetExecutionPodDetailsResponse = {
    executionPod: ExecutionPod
    access: ExecutionPodAccess[]
    hasRepoToken: boolean
}

type CreateExecutionPodVersionRequest = {
    executionPodId: string
    tag: RepositoryTag | null
    applet: AppletPod | null
}

type ExecutionPodVersionIdentifier = {
    executionPodId: string
    versionId: string
}

type UpdateExecutionPodVersionRequest = ExecutionPodVersionIdentifier & {
    name: string
}

type BuildExecutionPodImageRequest = {
    executionPodId: string
    versionId: string
}

type BuildExecutionPodImageResponse = {
    versionId: string
    status: ExecutionPodVersionStatus
}

type RunExecutionPodRequest = {
    versionId: string
    executionPodId: string
    definition: PodExecutionDefinitionV1
}

export const executionPodsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        githubIntegrationUrl: builder.query<GitHubOAuthResult, string>({
            query: (id) => `/private/execution-pod/oauth/${id}/redirect/github`,
        }),
        gitlabIntegrationUrl: builder.query<GitHubOAuthResult, string>({
            query: (id) => `/private/execution-pod/oauth/${id}/redirect/gitlab`,
        }),

        listExecutionPods: builder.query<ExecutionPodWithAccess[], void>({
            query: () => '/private/execution-pod/list',
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'ExecutionPod' as const, id })),
                          { type: 'ExecutionPod', id: 'LIST' },
                      ]
                    : [{ type: 'ExecutionPod', id: 'LIST' }],
        }),

        getExecutionPodDetails: builder.query<GetExecutionPodDetailsResponse, string>({
            query: (id) => `/private/execution-pod/${id}`,
            providesTags: (result, error, id) => [{ type: 'ExecutionPod' as const, id }],
        }),

        createExecutionPod: builder.mutation<ExecutionPod, CreateExecutionPodRequest>({
            query: (req) => ({
                url: '/private/execution-pod',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: [{ type: 'ExecutionPod', id: 'LIST' }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: newPod } = await queryFulfilled
                    dispatch(
                        executionPodsApiSlice.util.updateQueryData('listExecutionPods', undefined, (draft) => {
                            draft.push({
                                ...newPod,
                                accessType: ExecutionPodAccessType.Administrator,
                            })
                        }),
                    )
                } catch (error) {
                    console.error('Error creating execution pod:', error)
                }
            },
        }),

        updateExecutionPod: builder.mutation<ExecutionPod, UpdateExecutionPodRequest>({
            query: (req) => ({
                url: `/private/execution-pod/${req.id}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result, err, arg) => [{ type: 'ExecutionPod', id: arg.id }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                // Optimistic update.
                const listPatchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData('listExecutionPods', undefined, (draft) => {
                        const pod = draft.find((p) => p.id === arg.id)
                        if (pod) {
                            Object.assign(pod, arg)
                        }
                    }),
                )

                const detailsPatchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData('getExecutionPodDetails', arg.id, (draft) => {
                        Object.assign(draft, arg)
                    }),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error updating execution pod:', error)
                    listPatchResult.undo()
                    detailsPatchResult.undo()
                }
            },
        }),

        addUserToExecutionPod: builder.mutation<ExecutionPodAccess, AddUserToExecutionPodRequest>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/access`,
                method: 'POST',
                body: req,
            }),
            invalidatesTags: (result, err, arg) => [{ type: 'ExecutionPod', id: arg.executionPodId }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: newAccess } = await queryFulfilled
                    dispatch(
                        executionPodsApiSlice.util.updateQueryData(
                            'getExecutionPodDetails',
                            arg.executionPodId,
                            (draft) => {
                                draft.access.push(newAccess)
                            },
                        ),
                    )
                } catch (error) {
                    console.error('Error adding user to execution pod:', error)
                }
            },
        }),

        removeUserFromExecutionPod: builder.mutation<void, RemoveUserFromExecutionPodRequest>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/access/${req.userId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, err, arg) => [{ type: 'ExecutionPod', id: arg.executionPodId }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData(
                        'getExecutionPodDetails',
                        arg.executionPodId,
                        (draft) => {
                            draft.access = draft.access.filter((access) => access.user.id !== arg.userId)
                        },
                    ),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error removing user from execution pod:', error)
                    patchResult.undo()
                }
            },
        }),

        updateUserAccess: builder.mutation<ExecutionPodAccess, UpdateUserAccessRequest>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/access/${req.userId}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result, err, arg) => [{ type: 'ExecutionPod', id: arg.executionPodId }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData(
                        'getExecutionPodDetails',
                        arg.executionPodId,
                        (draft) => {
                            const access = draft.access.find((a) => a.user.id === arg.userId)
                            if (access) {
                                access.accessType = arg.accessType
                            }
                        },
                    ),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error updating user access:', error)
                    patchResult.undo()
                }
            },
        }),

        listExecutionPodVersions: builder.query<ExecutionPodVersion[], string>({
            query: (executionPodId) => `/private/execution-pod/${executionPodId}/version/list`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'ExecutionPodVersion' as const, id })),
                          { type: 'ExecutionPodVersion', id: 'LIST' },
                      ]
                    : [{ type: 'ExecutionPodVersion', id: 'LIST' }],
        }),

        getExecutionPodVersionDetails: builder.query<ExecutionPodVersion, ExecutionPodVersionIdentifier>({
            query: ({ executionPodId, versionId }) => `/private/execution-pod/${executionPodId}/version/${versionId}`,
            providesTags: (result) => (result ? [{ type: 'ExecutionPodVersion', id: result.id }] : []),
        }),

        getAvailableRepositoryTags: builder.query<RepositoryTag[], string>({
            query: (executionPodId) => `/private/execution-pod/${executionPodId}/version/tags`,
        }),

        createExecutionPodVersion: builder.mutation<ExecutionPodVersion, CreateExecutionPodVersionRequest>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/version`,
                method: 'POST',
                body: req,
            }),
            invalidatesTags: [{ type: 'ExecutionPodVersion', id: 'LIST' }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: newVersion } = await queryFulfilled
                    dispatch(
                        executionPodsApiSlice.util.updateQueryData(
                            'listExecutionPodVersions',
                            arg.executionPodId,
                            (draft) => {
                                draft.push(newVersion)
                            },
                        ),
                    )
                } catch (error) {
                    console.error('Error creating execution pod version:', error)
                }
            },
        }),

        updateExecutionPodVersion: builder.mutation<ExecutionPodVersion, UpdateExecutionPodVersionRequest>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/version/${req.versionId}`,
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result, err, arg) => [{ type: 'ExecutionPodVersion', id: arg.versionId }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData(
                        'listExecutionPodVersions',
                        arg.executionPodId,
                        (draft) => {
                            const version = draft.find((v) => v.id === arg.versionId)
                            if (version) {
                                Object.assign(version, arg)
                            }
                        },
                    ),
                )

                const detailsPatchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData(
                        'getExecutionPodVersionDetails',
                        { executionPodId: arg.executionPodId, versionId: arg.versionId },
                        (draft) => {
                            Object.assign(draft, arg)
                        },
                    ),
                )
                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error updating execution pod version:', error)
                    patchResult.undo()
                    detailsPatchResult.undo()
                }
            },
        }),

        buildExecutionPodImage: builder.mutation<BuildExecutionPodImageResponse, BuildExecutionPodImageRequest>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/version/${req.versionId}/build`,
                method: 'POST',
            }),
            invalidatesTags: (result, err, arg) => [{ type: 'ExecutionPodVersion', id: arg.versionId }],
        }),

        runExecutionPod: builder.mutation<void, RunExecutionPodRequest>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/version/${req.versionId}/execute`,
                method: 'POST',
                body: req.definition,
            }),
            invalidatesTags: () => [{ type: 'PipelineExecution', id: 'LIST' }],
        }),

        deleteExecutionPodVersion: builder.mutation<void, ExecutionPodVersionIdentifier>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/version/${req.versionId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, err, arg) => [
                { type: 'ExecutionPod', id: arg.executionPodId },
                { type: 'ExecutionPodVersion', id: 'LIST' },
            ],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData(
                        'listExecutionPodVersions',
                        arg.executionPodId,
                        (draft) => {
                            return draft.filter((version) => version.id !== arg.versionId)
                        },
                    ),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error deleting execution pod version:', error)
                    patchResult.undo()
                }
            },
        }),

        archiveExecutionPodVersion: builder.mutation<void, ExecutionPodVersionIdentifier>({
            query: (req) => ({
                url: `/private/execution-pod/${req.executionPodId}/version/${req.versionId}/archive`,
                method: 'POST',
            }),
            invalidatesTags: (result, err, arg) => [
                { type: 'ExecutionPod', id: arg.executionPodId },
                { type: 'ExecutionPodVersion', id: arg.versionId },
            ],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData(
                        'listExecutionPodVersions',
                        arg.executionPodId,
                        (draft) => {
                            const version = draft.find((v) => v.id === arg.versionId)
                            if (version) {
                                version.status = ExecutionPodVersionStatus.Archived
                            }
                        },
                    ),
                )

                const versionPatchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData('getExecutionPodVersionDetails', arg, (draft) => {
                        if (draft) {
                            draft.status = ExecutionPodVersionStatus.Archived
                        }
                    }),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error archiving execution pod version:', error)
                    patchResult.undo()
                    versionPatchResult.undo()
                }
            },
        }),

        deleteExecutionPod: builder.mutation<void, string>({
            query: (id) => ({
                url: `/private/execution-pod/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'ExecutionPod', id: 'LIST' }],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    executionPodsApiSlice.util.updateQueryData('listExecutionPods', undefined, (draft) => {
                        return draft.filter((pod) => pod.id !== id)
                    }),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error deleting execution pod:', error)
                    patchResult.undo()
                }
            },
        }),
    }),
})

export const {
    useLazyGithubIntegrationUrlQuery,
    useLazyGitlabIntegrationUrlQuery,
    useListExecutionPodsQuery,
    useGetExecutionPodDetailsQuery,
    useCreateExecutionPodMutation,
    useUpdateExecutionPodMutation,
    useAddUserToExecutionPodMutation,
    useRemoveUserFromExecutionPodMutation,
    useUpdateUserAccessMutation,
    useListExecutionPodVersionsQuery,
    useGetExecutionPodVersionDetailsQuery,
    useGetAvailableRepositoryTagsQuery,
    useCreateExecutionPodVersionMutation,
    useUpdateExecutionPodVersionMutation,
    useBuildExecutionPodImageMutation,
    useRunExecutionPodMutation,
    useDeleteExecutionPodVersionMutation,
    useArchiveExecutionPodVersionMutation,
    useDeleteExecutionPodMutation,
} = executionPodsApiSlice
