import { Autocomplete, Box, Button, createFilterOptions, SxProps, TextField } from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import { ObsField } from '../../model/analysisCommands'
import { receivedAppMessage } from '../dashboard/appMessageSlice'
import CreateDataSliceDialog from './CreateDataSliceDialog'
import { DataSliceAutocompleteResult, useLazyGetDataSlicesQuery } from './dataSliceApiSlice'
import { MicroarrayWorkflow, RnaSeqWorkflow, ScRnaSeqWorkflow, OlinkProteomicsWorkflow, NCounterWorkflow } from '../../model/model'

type DataSliceSelectorProps = {
    analysisId: number | undefined
    analysisWorkflow: string | undefined
    label: string
    helperText?: string
    obsFields: ObsField[] | undefined
    value: DataSliceAutocompleteResult | null
    setValue: (newValue: DataSliceAutocompleteResult | null) => void
    error: boolean
    required: boolean
    sx?: SxProps
}

export default function DataSliceSelector(props: DataSliceSelectorProps) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [openCreateDataSliceDialog, setOpenCreateDataSliceDialog] = useState(false)
    const [newSliceName, setNewSliceName] = useState<string>('')

    const [getDataSlicesApi, { data, isLoading }] = useLazyGetDataSlicesQuery()

    const getDataSlices = useCallback(
        debounce((keyword: string) => {
            if (!props.analysisId) {
                return
            }
            getDataSlicesApi({ keyword: keyword, analysisId: props.analysisId }).catch(() => {
                dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: 'Error loading data slices.',
                    }),
                )
            })
        }, 300),
        [props.analysisId],
    )

    const filter = createFilterOptions<DataSliceAutocompleteResult>()

    useEffect(() => {
        getDataSlices('')
    }, [])

    const createNewLabel = 'Create new data slice'

    const navUri = useMemo(() => {
        switch (props.analysisWorkflow) {
            case ScRnaSeqWorkflow:
                return `/analysis/scrnaseq/${props.analysisId}/dataslice`
            case RnaSeqWorkflow:
                return `/analysis/rnaseq/${props.analysisId}/dataslice`
            case MicroarrayWorkflow:
                return `/analysis/microarray/${props.analysisId}/dataslice`
            case OlinkProteomicsWorkflow:
                return `/analysis/prot/${props.analysisId}/dataslice`
            case NCounterWorkflow:
                return `/analysis/ncounter/${props.analysisId}/dataslice`
            default:
                return '/'
        }
    }, [props.analysisWorkflow, props.analysisId])

    return (
        <Box sx={props.sx}>
            <Autocomplete
                value={props.value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        size='small'
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            },
                        }}
                        required={props.required}
                        error={props.error}
                        helperText={props.helperText}
                    />
                )}
                onInputChange={(_, newInputValue) => {
                    getDataSlices(newInputValue)
                }}
                onChange={(_, newValue: DataSliceAutocompleteResult | null) => {
                    if (newValue && newValue.name.includes(createNewLabel)) {
                        setNewSliceName(newValue?.inputValue ?? '')
                        setOpenCreateDataSliceDialog(true)
                        props.setValue(null)
                    } else {
                        props.setValue(newValue)
                    }
                }}
                options={data ?? []}
                isOptionEqualToValue={(a, b) => a.id === b.id}
                loading={isLoading}
                getOptionLabel={(option) => {
                    return option.name
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    filtered.push({
                        inputValue: params.inputValue,
                        analysisId: props.analysisId,
                        name: createNewLabel,
                    })
                    return filtered
                }}
                renderOption={(roProps, option) => {
                    if (option.name == createNewLabel) {
                        return (
                            <li {...roProps} key={option.id} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button
                                    variant={'contained'}
                                    fullWidth
                                    sx={{ mb: 1 }}
                                    size={'small'}
                                    key={`btn-name-${option.id}`}
                                >
                                    {option.name}
                                </Button>
                                <Button
                                    key={`btn-manage`}
                                    variant={'outlined'}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        navigate(navUri)
                                    }}
                                    size={'small'}
                                    fullWidth
                                >
                                    Manage data slices
                                </Button>
                            </li>
                        )
                    }
                    return (
                        <li {...roProps} key={option.id}>
                            {option.name}
                        </li>
                    )
                }}
                handleHomeEndKeys
            />
            <CreateDataSliceDialog
                obsFields={props.obsFields}
                openDialog={openCreateDataSliceDialog}
                handleCloseDialog={() => {
                    setOpenCreateDataSliceDialog(false)
                }}
                callback={(dataSlice) => {
                    props.setValue(dataSlice)
                }}
                name={newSliceName}
            />
        </Box>
    )
}
