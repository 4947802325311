import { ChangeEvent, useState } from 'react'
import { Box, CircularProgress, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import CreateIcon from '@mui/icons-material/Create'
import { SxProps } from '@mui/system'

export default function InlineEditableProp(props: {
    value: string | null
    subtitle?: string | null
    save: (v: string) => void
    notEmpty?: boolean
    sx?: SxProps
    truncateAt?: number
}) {
    const [editMode, setEditMode] = useState(false)
    const [internalValue, setInternalValue] = useState('')
    const [internalValueError, setInternalValueError] = useState(false)
    const [formSubmitted, setFormSubmitted] = useState(false)

    const handleInternalValueChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setInternalValue(event.target.value)
    }

    return (
        <>
            {editMode ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        ...props.sx,
                    }}
                >
                    <TextField
                        size='small'
                        sx={{ mr: 1 }}
                        value={internalValue}
                        onChange={handleInternalValueChange}
                        disabled={formSubmitted}
                        error={internalValueError}
                    />
                    <Button
                        variant={'contained'}
                        sx={{ mr: 1 }}
                        onClick={async () => {
                            if (props.notEmpty && internalValue.trim() == '') {
                                setInternalValueError(true)
                                return
                            }
                            setInternalValueError(false)
                            setFormSubmitted(true)
                            props.save(internalValue)
                            setFormSubmitted(false)
                            setEditMode(false)
                        }}
                        disabled={formSubmitted}
                    >
                        {formSubmitted ? <CircularProgress size={20} /> : 'Save'}
                    </Button>
                    <Button
                        variant={'outlined'}
                        onClick={() => {
                            setEditMode(false)
                        }}
                        disabled={formSubmitted}
                    >
                        Cancel
                    </Button>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '36px' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Tooltip title={props.value} arrow>
                            <Typography sx={{ mr: 1, fontSize: '14px', fontWeight: 'bold' }}>
                                {props.truncateAt ? props.value?.truncate(props.truncateAt) : props.value}
                            </Typography>
                        </Tooltip>
                        {props.subtitle && <Typography sx={{ fontSize: '11px' }}>{props.subtitle}</Typography>}
                    </Box>
                    <Tooltip title={'Click to edit'} arrow>
                        <IconButton
                            onClick={() => {
                                if (props.value) {
                                    setInternalValue(props.value)
                                }
                                setEditMode(!editMode)
                            }}
                            size={'small'}
                        >
                            <CreateIcon fontSize={'small'} />
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </>
    )
}
