import { wsSlice } from '../../app/wsSlice'
import {
    BulkExpOmicsPrepareAnalysis,
    NCounterPrepareAnalysis,
    ScRnaSeqPrepareAnalysis,
    ScRnaSeqPrepareAnalysisFromAsset,
} from '../../model/analysisComponents'
import { DoneCommand, FailedAnalysis, InProgressAnalysis } from '../../model/model'
import { receivedAnalysisStatusUpdate } from './workbenchSlice'

/**
 * Initialize WebSocket handlers for workbench status updates
 */
export function initWorkbenchWebSocketListeners() {
    wsSlice.injectSelectHandlers(
        (m) => m.analysisCommandFinished,
        [
            (p, dispatch) => {
                switch (p.component) {
                    case BulkExpOmicsPrepareAnalysis:
                    case ScRnaSeqPrepareAnalysis:
                    case ScRnaSeqPrepareAnalysisFromAsset:
                    case NCounterPrepareAnalysis:
                        dispatch(
                            receivedAnalysisStatusUpdate({
                                analysisIdList: [p.analysisId],
                                status: p.status == DoneCommand ? InProgressAnalysis : FailedAnalysis,
                            }),
                        )
                        break
                }
            },
        ],
    )
}
