import { GridFilterModel } from '@mui/x-data-grid-premium'
import { apiSlice } from '../../../app/apiSlice'
import { LogEvent, PipelineExecution, PipelineExecutionSummary } from '../../../model/pipeline'

type JobComputeOptions = {
    instanceType: string
    vcpu: number
    memoryMbOptions: number[]
    pricePerHourUsd: Record<string, number>
}

export type ListPipelineExecutionsRequest = {
    gridFilterModel?: GridFilterModel
    pageNumber: number
    pageSize: number
    sortBy: string | null
    order: 'asc' | 'desc' | null | undefined
}

export type ListPipelineExecutionsResponse = {
    pipelineExecutions: PipelineExecutionSummary[]
    totalCount: number
}

type LogsRequest = {
    pipelineExecutionId: string
    stageId: string
    startTime: string | null
    endTime: string | null
    nextToken: string | null
}

type LogsResponse = {
    logs: LogEvent[]
    nextToken: string | null
}

export const pipelineApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        listPipelineExecutions: builder.query<ListPipelineExecutionsResponse, ListPipelineExecutionsRequest>({
            query: (params) => ({
                url: '/private/pipeline/execution/list',
                method: 'POST',
                body: params,
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.pipelineExecutions.map(({ id }) => ({ type: 'PipelineExecution' as const, id })),
                          { type: 'PipelineExecution', id: 'LIST' },
                      ]
                    : [{ type: 'PipelineExecution', id: 'LIST' }],
        }),

        getPipelineExecutionDetails: builder.query<PipelineExecution, string>({
            query: (id) => `/private/pipeline/execution/${id}`,
            providesTags: (result, error, id) => [{ type: 'PipelineExecution' as const, id }],
        }),

        terminatePipelineExecution: builder.mutation<void, string>({
            query: (id) => ({
                url: `/private/pipeline/execution/${id}/terminate`,
                method: 'POST',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'PipelineExecution', id }],
        }),

        getJobComputeOptions: builder.query<JobComputeOptions[], void>({
            query: () => '/private/pipeline/compute-options',
        }),

        getPipelineExecutionStageLogs: builder.query<LogsResponse, LogsRequest>({
            query: (params) => ({
                url: `/private/pipeline/execution/${params.pipelineExecutionId}/stage/${params.stageId}/logs`,
                method: 'POST',
                body: params,
            }),
        }),
    }),
})

export const {
    useListPipelineExecutionsQuery,
    useGetPipelineExecutionDetailsQuery,
    useTerminatePipelineExecutionMutation,
    useGetJobComputeOptionsQuery,
    useLazyGetPipelineExecutionStageLogsQuery,
} = pipelineApiSlice
