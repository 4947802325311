import {
    createAsyncThunk,
    createListenerMiddleware,
    createSlice,
    isRejectedWithValue,
    PayloadAction,
} from '@reduxjs/toolkit'
import i18n from 'i18next'
import { AppDispatch, RootState } from '../../app/store'

type AppMessageType = 'error' | 'warning' | 'info' | 'success' | undefined
type AppMessage = string | null | undefined

type AppMessageState = {
    message: string | null | undefined
    type: AppMessageType
    open: boolean
    navigationPath: string | null | undefined
}

const initialState = {
    type: undefined,
    message: null,
    open: false,
    navigationPath: null,
} as AppMessageState

const appMessageSlice = createSlice({
    name: 'appMessageHolder',
    initialState: initialState,
    reducers: {
        setAppMessage: (
            state,
            {
                payload: { type, message, navigationPath },
            }: PayloadAction<{
                type: AppMessageType
                message: string | null | undefined
                navigationPath?: string | null | undefined
            }>,
        ) => {
            state.open = true
            state.type = type
            state.message = message
            state.navigationPath = navigationPath
        },
        clearAppMessage: (state) => {
            state.open = false
        },
        clearAppMessageIf: (
            state,
            {
                payload: { type, message },
            }: PayloadAction<{
                type: AppMessageType
                message: AppMessage
            }>,
        ) => {
            if (state.open && state.type === type && state.message === message) {
                state.open = false
            }
        },
    },
})

export const receivedAppMessage = createAsyncThunk<
    void,
    { type: AppMessageType; message: AppMessage; navigationPath?: string | null; time?: number },
    { dispatch: AppDispatch; state: RootState }
>('appMessageSlice/receivedAppMessage', async ({ type, message, navigationPath, time }, { dispatch }) => {
    dispatch(
        appMessageSlice.actions.setAppMessage({
            type,
            message,
            navigationPath,
        }),
    )

    if (time == undefined) {
        const matches = message?.match(/\b\w+\b/g)
        time = matches ? matches.length : 1
        time *= 1000
    }

    return new Promise<void>((resolve) => {
        setTimeout(() => {
            dispatch(
                appMessageSlice.actions.clearAppMessageIf({
                    type,
                    message,
                }),
            )
            resolve()
        }, time)
    })
})

export const errorListenerMiddleware = createListenerMiddleware()

interface AppError {
    code: string
}

errorListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()({
    predicate: (action) => isRejectedWithValue(action),
    effect: async (action, listenerApi) => {
        const msg = i18n.t('error.INTERNAL_SERVER_ERROR')
        if (action.payload) {
            try {
                const payloadData = action.payload as {
                    data: unknown
                }

                let appError: AppError
                if (typeof payloadData.data === 'object') {
                    appError = payloadData.data as AppError
                } else {
                    appError = JSON.parse(payloadData.data as string) as AppError
                }
                listenerApi.dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: i18n.t(`error.${appError.code}`),
                    }),
                )
            } catch {
                listenerApi.dispatch(
                    receivedAppMessage({
                        type: 'error',
                        message: msg,
                    }),
                )
            }
        } else {
            console.error('Could not get error message from backend', action)
        }
    },
})

export const { clearAppMessage } = appMessageSlice.actions

export const selectAppMessageOpen = (state: RootState) => state.appMessageHolder.open
export const selectAppMessageType = (state: RootState) => state.appMessageHolder.type
export const selectAppMessage = (state: RootState) => state.appMessageHolder.message
export const selectAppNavigationPath = (state: RootState) => state.appMessageHolder.navigationPath

export default appMessageSlice.reducer
