import { wsSlice } from '../../app/wsSlice'
import { receivedAppMessage } from './appMessageSlice'

/**
 * Initialize WebSocket handlers for app messages
 */
export function initAppMessageWebSocketListeners() {
    wsSlice.injectHandlers([
        (m, dispatch) => {
            if (!m.error) {
                return
            }

            dispatch(
                receivedAppMessage({
                    type: 'error',
                    message: m.error,
                }),
            )
        },
    ])
}
