import { ThemeProvider } from '@mui/material/styles'
import { LicenseInfo } from '@mui/x-license-pro'
import * as dfd from 'danfojs'
import { jwtDecode } from 'jwt-decode'
import { lazy, Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { CookieTokenKey, StorageTokenKey } from './app/storageKeys'
import UserList from './features/admin/user/UserList'
import Forbidden from './features/auth/Forbidden'
import RestrictedRoute from './features/auth/RestrictedRoute'
import StartupLoading from './features/auth/StartupLoading'
import Explorer from './features/omicsbrowser/Explorer'
import AnalysisDashboard from './features/workbench/analysis/AnalysisDashboard'
import { PanomicsJwtPayload } from './model/model'
import PanomicsTheme from './theme'

import { setAuthenticating, setAuthentication, setFinishedAuthenticating } from './features/auth/authSlice'
import { useLazyGetUserQuery } from './features/user/userApiSlice'

import MetadataFieldList from './features/admin/metadatafield/MetadataFieldList'
import OntologyList from './features/admin/ontology/OntologyList'
import RoleList from './features/admin/role/RoleList'
import OrganizationSettings from './features/admin/settings/OrganizationSettings'

import config from './app/config'
import { useAppDispatch } from './app/hooks'
import './app/wsImports'
import CollectionList from './features/admin/collection/CollectionList'
import NoAccount from './features/auth/NoAccount'
import EmailConfirmation from './features/signup/EmailConfirmation'
import ProteomicsAnalysisComputeStatistics from './features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisComputeStatistics'
import { getCookieValue } from './utils/misc'

const SignIn = lazy(() => import('./features/auth/SignIn'))
const AcceptEULA = lazy(() => import('./features/user/AcceptEULA'))
const Frame = lazy(() => import('./features/dashboard/Frame'))
const AdminPageFrame = lazy(() => import('./features/admin/AdminPageFrame'))
const Workbench = lazy(() => import('./features/workbench/Workbench'))
const ImportSamples = lazy(() => import('./features/sample/import/ImportSamples'))
const ProjectDetails = lazy(() => import('./features/workbench/ProjectDetails'))
const AnalysisDetails = lazy(() => import('./features/workbench/analysis/AnalysisDetails'))
const CreateProject = lazy(() => import('./features/project/CreateProject'))
const ProjectList = lazy(() => import('./features/project/ProjectList'))
const OrganisationSampleList = lazy(() => import('./features/sample/OrgSampleListPage'))
const ProjectSampleList = lazy(() => import('./features/workbench/samples/ProjectSampleListPage'))
const AnalysisList = lazy(() => import('./features/workbench/analysis/AnalysisList'))
const AssetFileSystem = lazy(() => import('./features/workbench/asset/AssetFileSystem'))
const UploadAssets = lazy(() => import('./features/workbench/asset/UploadAssets'))
const AccountFrame = lazy(() => import('./features/user/AccountFrame'))
const AccountUserInfo = lazy(() => import('./features/user/AccountUserInfo'))
const AccountBillingInfo = lazy(() => import('./features/user/billing/AccountBillingInfo'))
const AccountApiKeyList = lazy(() => import('./features/user/AccountApiKeyList'))
const ExternalApiKeyList = lazy(() => import('./features/user/ExternalApiKeyList'))
const GeneLists = lazy(() => import('./features/genelist/GeneLists'))
const GuestList = lazy(() => import('./features/admin/guest/GuestList'))
const GeneSetCollectionList = lazy(() => import('./features/admin/genesetcollection/GeneSetCollectionList'))
const ExecutionPodsList = lazy(() => import('./features/customcompute/executionpods/ExecutionPodsList'))
const ExecutionPodDetails = lazy(() => import('./features/customcompute/executionpods/ExecutionPodDetails'))
const PipelineExecutionsPage = lazy(() => import('./features/customcompute/pipeline/PipelineExecutionsPage'))

// Single-cell RNA-seq Analysis
const ScRnaSeqAnalysisQc = lazy(
    () => import('./features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisQc'),
)
const ScRnaSeqAnalysisGeneExpression = lazy(
    () => import('./features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisGeneExpression'),
)
const ScRnaSeqAnalysisCellExplorer = lazy(
    () => import('./features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisCellExplorer'),
)
const ScRnaSeqAnalysisCellComposition = lazy(
    () => import('./features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisCellComposition'),
)
const ScRnaSeqAnalysisDifferentialExpression = lazy(
    () => import('./features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisDifferentialExpression'),
)
const ScRnaSeqAnalysisDifferentialPathwayActivity = lazy(
    () =>
        import(
            './features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisDifferentialPathwayActivity'
        ),
)
const ScRnaSeqAnalysisMarkerGenes = lazy(
    () => import('./features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisMarkerGenes'),
)
const ScRnaSeqAnalysisComputeStatistics = lazy(
    () => import('./features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisComputeStatistics'),
)
const ScRnaSeqAnalysisGeneSetEnrichment = lazy(
    () => import('./features/workbench/analysis/modules/modalities/singlecell/ScRnaSeqAnalysisGeneSetEnrichment'),
)
// RNA-seq Analysis
const RnaSeqAnalysisQc = lazy(() => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisQc'))
const RnaSeqAnalysisGeneExpression = lazy(
    () => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisGeneExpression'),
)
const RnaSeqAnalysisDifferentialExpression = lazy(
    () => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisDifferentialExpression'),
)
const RnaSeqAnalysisDifferentialPathwayActivity = lazy(
    () => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisDifferentialPathwayActivity'),
)
const RnaSeqAnalysisMarkerGenes = lazy(
    () => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisMarkerGenes'),
)
const RnaSeqAnalysisComputeStatistics = lazy(
    () => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisComputeStatistics'),
)
const RnaSeqAnalysisGeneSetEnrichment = lazy(
    () => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisGeneSetEnrichment'),
)
const RnaSeqAnalysisSampleExplorer = lazy(
    () => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisSampleExplorer'),
)
const RnaSeqAnalysisWGCNA = lazy(
    () => import('./features/workbench/analysis/modules/modalities/bulk/RnaSeqAnalysisWGCNA'),
)

// Microarray Analysis
const MicroarrayAnalysisQc = lazy(
    () => import('./features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisQc'),
)
const MicroarrayAnalysisGeneExpression = lazy(
    () => import('./features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisGeneExpression'),
)
const MicroarrayAnalysisMarkerGenes = lazy(
    () => import('./features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisMarkerGenes'),
)
const MicroarrayAnalysisComputeStatistics = lazy(
    () => import('./features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisComputeStatistics'),
)
const MicroarrayAnalysisGeneSetEnrichment = lazy(
    () => import('./features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisGeneSetEnrichment'),
)
const MicroarrayAnalysisSampleExplorer = lazy(
    () => import('./features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisSampleExplorer'),
)
const MicroarrayAnalysisWGCNA = lazy(
    () => import('./features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisWGCNA'),
)
const MicroarrayAnalysisDifferentialExpression = lazy(
    () =>
        import('./features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisDifferentialExpression'),
)
const MicroarrayAnalysisDifferentialPathwayActivity = lazy(
    () =>
        import(
            './features/workbench/analysis/modules/modalities/microarray/MicroarrayAnalysisDifferentialPathwayActivity'
        ),
)

// Proteomics Analysis
const ProteomicsAnalysisQc = lazy(
    () => import('./features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisQc'),
)
const ProteomicsAnalysisGeneExpression = lazy(
    () => import('./features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisGeneExpression'),
)
const ProteomicsAnalysisMarkerGenes = lazy(
    () => import('./features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisMarkerGenes'),
)
const ProteomicsAnalysisDifferentialExpression = lazy(
    () =>
        import('./features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisDifferentialExpression'),
)
const ProteomicsAnalysisDifferentialPathwayActivity = lazy(
    () =>
        import(
            './features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisDifferentialPathwayActivity'
        ),
)
const ProteomicsAnalysisGeneSetEnrichment = lazy(
    () => import('./features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisGeneSetEnrichment'),
)
const ProteomicsAnalysisSampleExplorer = lazy(
    () => import('./features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisSampleExplorer'),
)
const ProteomicsAnalysisWGCNA = lazy(
    () => import('./features/workbench/analysis/modules/modalities/proteomics/ProteomicsAnalysisWGCNA'),
)

const Readme = lazy(() => import('./features/workbench/readme/Readme'))

const DataSliceList = lazy(() => import('./features/dataslice/DataSliceList'))
const CellAtlasList = lazy(() => import('./features/cellatlas/CellAtlasList'))
const SignUp = lazy(() => import('./features/signup/SignUp'))

const ExecutionPodGitHubOAuthHandler = lazy(() => import('./features/oauth/execution-pod/GitHubCallbackHandler'))
const ExecutionPodGitLabOAuthHandler = lazy(() => import('./features/oauth/execution-pod/GitLabCallbackHandler'))

// Add these imports with the other lazy imports
const NCounterAnalysisQc = lazy(
    () => import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisQc'),
)
const NCounterAnalysisGeneExpression = lazy(
    () => import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisGeneExpression'),
)
const NCounterAnalysisSampleExplorer = lazy(
    () => import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisSampleExplorer'),
)
const NCounterAnalysisMarkerGenes = lazy(
    () => import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisMarkerGenes'),
)
const NCounterAnalysisComputeStatistics = lazy(
    () => import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisComputeStatistics'),
)
const NCounterAnalysisDifferentialExpression = lazy(
    () => import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisDifferentialExpression'),
)
const NCounterAnalysisDifferentialPathwayActivity = lazy(
    () =>
        import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisDifferentialPathwayActivity'),
)
const NCounterAnalysisWGCNA = lazy(
    () => import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisWGCNA'),
)
const NCounterAnalysisGeneSetEnrichment = lazy(
    () => import('./features/workbench/analysis/modules/modalities/ncounter/NCounterAnalysisGeneSetEnrichment'),
)

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE)

function App() {
    const dispatch = useAppDispatch()
    const [getUserApi] = useLazyGetUserQuery()

    const getToken = () => {
        let token = getCookieValue(CookieTokenKey)
        if (!token || token == '') {
            token = localStorage.getItem(StorageTokenKey)
        }
        return token
    }

    const authenticate = async () => {
        try {
            const token = getToken()
            if (token != null) {
                const decodedToken = jwtDecode<PanomicsJwtPayload>(token)
                const exp = decodedToken.exp
                const now = new Date().getTime()
                if (exp && now < exp * 1000) {
                    dispatch(setAuthenticating())
                    const user = await getUserApi(token).unwrap()
                    dispatch(
                        setAuthentication({
                            token: token,
                            user: user,
                        }),
                    )
                } else {
                    localStorage.removeItem(StorageTokenKey)
                }
            }
        } finally {
            dispatch(setFinishedAuthenticating())
        }

        return false
    }

    useEffect(() => {
        if (typeof dfd === 'undefined') {
            console.error('dependency error')
        }
        authenticate().catch(console.error)
    }, [])

    return (
        <ThemeProvider theme={PanomicsTheme}>
            <Routes>
                <Route
                    index
                    element={
                        <Suspense fallback={<StartupLoading />}>
                            <SignIn />
                        </Suspense>
                    }
                />
                {config.signupEnabled && (
                    <Route
                        path='signup'
                        element={
                            <Suspense fallback={<StartupLoading />}>
                                <SignUp />
                            </Suspense>
                        }
                    />
                )}
                <Route
                    path='email-confirmation/:code'
                    element={
                        <Suspense fallback={<StartupLoading />}>
                            <EmailConfirmation />
                        </Suspense>
                    }
                />
                <Route
                    path='error'
                    element={
                        <Suspense fallback={<StartupLoading />}>
                            <Frame />
                        </Suspense>
                    }
                >
                    <Route
                        path='forbidden'
                        element={
                            <Suspense fallback={<></>}>
                                <Forbidden />
                            </Suspense>
                        }
                    />
                    <Route
                        path='no-account'
                        element={
                            <Suspense fallback={<></>}>
                                <NoAccount />
                            </Suspense>
                        }
                    />
                </Route>
                <Route
                    path='eula'
                    element={
                        <Suspense fallback={<></>}>
                            <AcceptEULA />
                        </Suspense>
                    }
                />
                <Route element={<RestrictedRoute />}>
                    <Route
                        path='app'
                        element={
                            <Suspense fallback={<></>}>
                                <Frame />
                            </Suspense>
                        }
                    >
                        <Route
                            path='admin'
                            element={
                                <Suspense fallback={<></>}>
                                    <AdminPageFrame />
                                </Suspense>
                            }
                        >
                            <Route element={<RestrictedRoute requiredPermissions={{ manageRoles: true }} />}>
                                <Route
                                    path='roles'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <RoleList />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route element={<RestrictedRoute requiredPermissions={{ manageUsers: true }} />}>
                                <Route
                                    path='users'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <UserList />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route element={<RestrictedRoute requiredPermissions={{ manageMetadata: true }} />}>
                                <Route
                                    path='ontology'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <OntologyList />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path='metadata-field'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <MetadataFieldList />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route element={<RestrictedRoute requiredPermissions={{ manageCollections: true }} />}>
                                <Route
                                    path='collection'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <CollectionList />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route
                                element={<RestrictedRoute requiredPermissions={{ manageGeneSetCollections: true }} />}
                            >
                                <Route
                                    path='gene-set-collection'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <GeneSetCollectionList />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route element={<RestrictedRoute requiredPermissions={{ manageGuests: true }} />}>
                                <Route
                                    path='guests'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <GuestList />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route element={<RestrictedRoute requiredPermissions={{ manageOrgSettings: true }} />}>
                                <Route
                                    path='settings'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <OrganizationSettings />
                                        </Suspense>
                                    }
                                />
                            </Route>
                        </Route>
                        <Route
                            path='workbench'
                            element={
                                <Suspense fallback={<></>}>
                                    <Workbench />
                                </Suspense>
                            }
                        >
                            <Route
                                path=':id/samples'
                                element={
                                    <Suspense fallback={<></>}>
                                        <ProjectSampleList />
                                    </Suspense>
                                }
                            />
                            <Route
                                path=':id/analyses'
                                element={
                                    <Suspense fallback={<></>}>
                                        <AnalysisList />
                                    </Suspense>
                                }
                            />
                            <Route
                                path=':id/analysis/:aid'
                                element={
                                    <Suspense fallback={<></>}>
                                        <AnalysisDetails />
                                    </Suspense>
                                }
                            />
                            <Route
                                path=':id/assets/upload/:pid'
                                element={
                                    <Suspense fallback={<></>}>
                                        <UploadAssets />
                                    </Suspense>
                                }
                            />
                            <Route
                                path=':id/assets'
                                element={
                                    <Suspense fallback={<></>}>
                                        <AssetFileSystem />
                                    </Suspense>
                                }
                            />
                            <Route
                                path=':id/readme'
                                element={
                                    <Suspense fallback={<></>}>
                                        <Readme />
                                    </Suspense>
                                }
                            />
                            <Route
                                path=':id'
                                element={
                                    <Suspense fallback={<></>}>
                                        <ProjectDetails />
                                    </Suspense>
                                }
                            />
                        </Route>
                    </Route>
                    <Route element={<RestrictedRoute />}>
                        <Route
                            path='project'
                            element={
                                <Suspense fallback={<></>}>
                                    <Frame />
                                </Suspense>
                            }
                        >
                            <Route
                                path='list'
                                element={
                                    <Suspense fallback={<></>}>
                                        <ProjectList />
                                    </Suspense>
                                }
                            />
                            <Route element={<RestrictedRoute requiredPermissions={{ createProjects: true }} />}>
                                <Route
                                    path='create'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <CreateProject />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route
                                path='details/:id'
                                element={
                                    <Suspense fallback={<></>}>
                                        <ProjectDetails />
                                    </Suspense>
                                }
                            />
                        </Route>
                    </Route>
                    <Route element={<RestrictedRoute />}>
                        <Route
                            path='sample'
                            element={
                                <Suspense fallback={<></>}>
                                    <Frame />
                                </Suspense>
                            }
                        >
                            <Route element={<RestrictedRoute requiredPermissions={{ browseSamples: true }} />}>
                                <Route
                                    path='list'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <OrganisationSampleList />
                                        </Suspense>
                                    }
                                />
                            </Route>
                            <Route element={<RestrictedRoute requiredPermissions={{ importSamples: true }} />}>
                                <Route
                                    element={<RestrictedRoute requiredPermissions={{ manageProjectSamples: true }} />}
                                >
                                    <Route
                                        path='import/:id'
                                        element={
                                            <Suspense fallback={<></>}>
                                                <ImportSamples />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                                <Route
                                    path='import'
                                    element={
                                        <Suspense fallback={<></>}>
                                            <ImportSamples />
                                        </Suspense>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                    <Route element={<RestrictedRoute />}>
                        <Route
                            path='gene-list'
                            element={
                                <Suspense fallback={<></>}>
                                    <Frame />
                                </Suspense>
                            }
                        >
                            <Route
                                path='list'
                                element={
                                    <Suspense fallback={<></>}>
                                        <GeneLists />
                                    </Suspense>
                                }
                            />
                        </Route>
                    </Route>
                    <Route element={<RestrictedRoute />}>
                        <Route
                            path='execution-pod'
                            element={
                                <Suspense fallback={<></>}>
                                    <Frame />
                                </Suspense>
                            }
                        >
                            <Route
                                path='list'
                                element={
                                    <Suspense fallback={<></>}>
                                        <ExecutionPodsList />
                                    </Suspense>
                                }
                            />

                            <Route
                                path=':id'
                                element={
                                    <Suspense fallback={<></>}>
                                        <ExecutionPodDetails />
                                    </Suspense>
                                }
                            />
                        </Route>
                    </Route>
                    <Route element={<RestrictedRoute />}>
                        <Route
                            path='pipeline/executions'
                            element={
                                <Suspense fallback={<></>}>
                                    <Frame />
                                </Suspense>
                            }
                        >
                            <Route
                                index
                                element={
                                    <Suspense fallback={<></>}>
                                        <PipelineExecutionsPage />
                                    </Suspense>
                                }
                            />
                        </Route>
                    </Route>
                    <Route element={<RestrictedRoute />}>
                        <Route
                            path='account'
                            element={
                                <Suspense fallback={<></>}>
                                    <Frame />
                                </Suspense>
                            }
                        >
                            <Route
                                path='info'
                                element={
                                    <Suspense fallback={<></>}>
                                        <AccountFrame />
                                    </Suspense>
                                }
                            >
                                <Route element={<RestrictedRoute requiredPermissions={{ editProfile: true }} />}>
                                    <Route
                                        path='user'
                                        element={
                                            <Suspense fallback={<></>}>
                                                <AccountUserInfo />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                                <Route element={<RestrictedRoute requiredPermissions={{ viewBillingInfo: true }} />}>
                                    <Route
                                        path='billing'
                                        element={
                                            <Suspense fallback={<></>}>
                                                <AccountBillingInfo />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                                <Route element={<RestrictedRoute requiredPermissions={{ manageApiKeys: true }} />}>
                                    <Route
                                        path='apikey'
                                        element={
                                            <Suspense fallback={<></>}>
                                                <AccountApiKeyList />
                                            </Suspense>
                                        }
                                    />
                                    <Route
                                        path='external-apikey'
                                        element={
                                            <Suspense fallback={<></>}>
                                                <ExternalApiKeyList />
                                            </Suspense>
                                        }
                                    />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route element={<RestrictedRoute requiredPermissions={{ useOmicsBrowser: true }} />}>
                        <Route
                            path='omicsbrowser'
                            element={
                                <Suspense fallback={<></>}>
                                    <Frame />
                                </Suspense>
                            }
                        >
                            <Route
                                path='dashboard'
                                element={
                                    <Suspense fallback={<></>}>
                                        <Explorer />
                                    </Suspense>
                                }
                            />
                        </Route>
                    </Route>
                    <Route
                        path='cellatlas'
                        element={
                            <Suspense fallback={<></>}>
                                <Frame />
                            </Suspense>
                        }
                    >
                        <Route
                            path='list'
                            element={
                                <Suspense fallback={<></>}>
                                    <CellAtlasList />
                                </Suspense>
                            }
                        />
                    </Route>
                    <Route
                        path='*'
                        element={
                            <main style={{ padding: '1rem' }}>
                                <p>There is nothing here</p>
                            </main>
                        }
                    />
                </Route>
                <Route element={<RestrictedRoute />}>
                    <Route
                        path='analysis/rnaseq/:aid'
                        element={
                            <Suspense fallback={<></>}>
                                <AnalysisDashboard />
                            </Suspense>
                        }
                    >
                        <Route
                            path='qc'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisQc />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gex'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisGeneExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='sample-explorer'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisSampleExplorer />
                                </Suspense>
                            }
                        />
                        <Route
                            path='marker-genes'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisMarkerGenes />
                                </Suspense>
                            }
                        />
                        <Route
                            path='compute-statistics'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisComputeStatistics />
                                </Suspense>
                            }
                        />
                        <Route
                            path='deg'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisDifferentialExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dap'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisDifferentialPathwayActivity />
                                </Suspense>
                            }
                        />
                        <Route
                            path='wgcna'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisWGCNA />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gsea'
                            element={
                                <Suspense fallback={<></>}>
                                    <RnaSeqAnalysisGeneSetEnrichment />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dataslice'
                            element={
                                <Suspense fallback={<></>}>
                                    <DataSliceList />
                                </Suspense>
                            }
                        ></Route>
                    </Route>
                    <Route
                        path='analysis/scrnaseq/:aid'
                        element={
                            <Suspense fallback={<></>}>
                                <AnalysisDashboard />
                            </Suspense>
                        }
                    >
                        <Route
                            path='qc'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisQc />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gex'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisGeneExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='cell-explorer'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisCellExplorer />
                                </Suspense>
                            }
                        />
                        <Route
                            path='cell-composition'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisCellComposition />
                                </Suspense>
                            }
                        />
                        <Route
                            path='deg'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisDifferentialExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dap'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisDifferentialPathwayActivity />
                                </Suspense>
                            }
                        />
                        <Route
                            path='marker-genes'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisMarkerGenes />
                                </Suspense>
                            }
                        />
                        <Route
                            path='compute-statistics'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisComputeStatistics />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gsea'
                            element={
                                <Suspense fallback={<></>}>
                                    <ScRnaSeqAnalysisGeneSetEnrichment />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dataslice'
                            element={
                                <Suspense fallback={<></>}>
                                    <DataSliceList />
                                </Suspense>
                            }
                        ></Route>
                    </Route>
                </Route>
                <Route element={<RestrictedRoute />}>
                    <Route
                        path='analysis/microarray/:aid'
                        element={
                            <Suspense fallback={<></>}>
                                <AnalysisDashboard />
                            </Suspense>
                        }
                    >
                        <Route
                            path='qc'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisQc />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gex'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisGeneExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='sample-explorer'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisSampleExplorer />
                                </Suspense>
                            }
                        />
                        <Route
                            path='marker-genes'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisMarkerGenes />
                                </Suspense>
                            }
                        />
                        <Route
                            path='compute-statistics'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisComputeStatistics />
                                </Suspense>
                            }
                        />
                        <Route
                            path='deg'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisDifferentialExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dap'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisDifferentialPathwayActivity />
                                </Suspense>
                            }
                        />
                        <Route
                            path='wgcna'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisWGCNA />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gsea'
                            element={
                                <Suspense fallback={<></>}>
                                    <MicroarrayAnalysisGeneSetEnrichment />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dataslice'
                            element={
                                <Suspense fallback={<></>}>
                                    <DataSliceList />
                                </Suspense>
                            }
                        ></Route>
                    </Route>
                </Route>
                <Route element={<RestrictedRoute />}>
                    <Route
                        path='analysis/prot/:aid'
                        element={
                            <Suspense fallback={<></>}>
                                <AnalysisDashboard />
                            </Suspense>
                        }
                    >
                        <Route
                            path='qc'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisQc />
                                </Suspense>
                            }
                        />
                        <Route
                            path='compute-statistics'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisComputeStatistics />
                                </Suspense>
                            }
                        />
                        <Route
                            path='pex'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisGeneExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='sample-explorer'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisSampleExplorer />
                                </Suspense>
                            }
                        />
                        <Route
                            path='marker-proteins'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisMarkerGenes />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dep'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisDifferentialExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dap'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisDifferentialPathwayActivity />
                                </Suspense>
                            }
                        />
                        <Route
                            path='wgcna'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisWGCNA />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gsea'
                            element={
                                <Suspense fallback={<></>}>
                                    <ProteomicsAnalysisGeneSetEnrichment />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dataslice'
                            element={
                                <Suspense fallback={<></>}>
                                    <DataSliceList />
                                </Suspense>
                            }
                        ></Route>
                    </Route>
                </Route>

                <Route path='oauth' element={<RestrictedRoute />}>
                    <Route path='execution-pod'>
                        <Route
                            path='github'
                            element={
                                <Suspense fallback={<></>}>
                                    <ExecutionPodGitHubOAuthHandler />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gitlab'
                            element={
                                <Suspense fallback={<></>}>
                                    <ExecutionPodGitLabOAuthHandler />
                                </Suspense>
                            }
                        />
                    </Route>
                </Route>
                <Route element={<RestrictedRoute />}>
                    <Route
                        path='analysis/ncounter/:aid'
                        element={
                            <Suspense fallback={<></>}>
                                <AnalysisDashboard />
                            </Suspense>
                        }
                    >
                        <Route
                            path='qc'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisQc />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gex'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisGeneExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='sample-explorer'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisSampleExplorer />
                                </Suspense>
                            }
                        />
                        <Route
                            path='marker-genes'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisMarkerGenes />
                                </Suspense>
                            }
                        />
                        <Route
                            path='compute-statistics'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisComputeStatistics />
                                </Suspense>
                            }
                        />
                        <Route
                            path='deg'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisDifferentialExpression />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dap'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisDifferentialPathwayActivity />
                                </Suspense>
                            }
                        />
                        <Route
                            path='wgcna'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisWGCNA />
                                </Suspense>
                            }
                        />
                        <Route
                            path='gsea'
                            element={
                                <Suspense fallback={<></>}>
                                    <NCounterAnalysisGeneSetEnrichment />
                                </Suspense>
                            }
                        />
                        <Route
                            path='dataslice'
                            element={
                                <Suspense fallback={<></>}>
                                    <DataSliceList />
                                </Suspense>
                            }
                        />
                    </Route>
                </Route>
            </Routes>
        </ThemeProvider>
    )
}

export default App
