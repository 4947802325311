import { createTheme } from '@mui/material/styles'

const raleway = {
    fontFamily: ['Raleway', 'sans-serif'].join(','),
    fontWeight: 700,
}

const { palette } = createTheme()

const PanomicsTheme = createTheme({
    palette: {
        primary: {
            main: '#2a9d8f',
            dark: '#287473',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#f4a261',
        },
        error: {
            main: '#e76f51',
            dark: '#DD0000',
        },
    },
    typography: {
        // general styling
        fontFamily: 'Roboto',
        fontSize: 12,
        allVariants: {
            color: '#264653',
        },
        // element specific styling
        h1: raleway,
        h2: raleway,
        h3: raleway,
        h4: raleway,
        h5: raleway,
        h6: raleway,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                a {
                  cursor: pointer;
                }
                pre {
                    overflow-x:auto; 
                    overflow-y:auto; 
                    word-break:break-all;
                    word-wrap:break-word; 
                    white-space:pre-wrap;
                }
              `,
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    '&.disabled': {
                        color: palette.action.disabled,
                        pointerEvents: 'none',
                    },
                    '&.disabled *': {
                        color: palette.action.disabled,
                    },
                },
            },
        },
    },
})

export default PanomicsTheme
