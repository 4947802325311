import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { Badge, Box, Link, Menu, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { SxProps } from '@mui/system'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Notification, NotificationType } from '../../model/model'
import { useLazyGetLatestQuery, useMarkAllAsReadMutation, useMarkAsReadMutation } from './notificationApiSlice'
import {
    receivedAllNotificationsRead,
    receivedNewNotifications,
    receivedNotificationRead,
    receivedNotifications,
    selectAllNotifications,
    selectTotalCount,
    selectTotalUnreadCount,
} from './notificationSlice'
import AnalysisResult from './types/AnalysisResult'
import DuplicateSampleNames from './types/DuplicateSampleNames'
import ExecutionPodBuildResult from './types/ExecutionPodBuildResult'
import GeneCoPerturbationResult from './types/GeneCoPerturbationResult'
import PipelineExecutionResult from './types/PipelineExecutionResult'
import ProjectAccess from './types/ProjectAccess'
import ReadmeMention from './types/ReadmeMention'
import SampleMetadataUploadErrors from './types/SampleMetadataUploadErrors'

interface NotificationsParams {
    sx?: SxProps
}

const defaultLimit = 20

export default function Notifications({ sx }: NotificationsParams) {
    const dispatch = useAppDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const totalUnreadCount = useAppSelector(selectTotalUnreadCount)
    const [markAllAsReadApi] = useMarkAllAsReadMutation()
    const [getLatestNotificationsApi] = useLazyGetLatestQuery()

    useEffect(() => {
        getLatestNotificationsApi({
            offset: 0,
            limit: defaultLimit,
        })
            .unwrap()
            .then((resp) => {
                dispatch(
                    receivedNotifications({
                        notificationList: resp.notifications,
                        totalCount: resp.totalCount,
                        totalUnreadCount: resp.totalUnreadCount,
                    }),
                )
            })
    }, [getLatestNotificationsApi])

    const markAllAsRead = async () => {
        await markAllAsReadApi().unwrap()
        dispatch(receivedAllNotificationsRead())
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <IconButton
                sx={{ color: '#fff', ...sx }}
                onClick={handleClick}
                aria-controls={open ? 'notifications-list' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
            >
                <Badge badgeContent={totalUnreadCount} color='error'>
                    <NotificationsIcon sx={{ fontSize: '1.25em' }} />
                </Badge>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id='notifications-list'
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    dense: true,
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        ml: 1,
                        mr: 1,
                        mb: 1,
                        alignItems: 'center',
                        p: 1,
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <NotificationsNoneIcon sx={{ fontSize: '1.5em', mr: 1 }} />
                        <Typography sx={{ fontSize: '1.1em' }}>Notifications</Typography>
                    </Box>
                    {totalUnreadCount > 0 && (
                        <Link sx={{ fontSize: '0.8em' }} onClick={markAllAsRead}>
                            Mark all as read
                        </Link>
                    )}
                </Box>
                <Divider />
                <Box
                    sx={{
                        boxShadow: '0px 8px 24px 1px rgba(0, 0, 0)',
                        transition: 'box-shadow 150ms ease-in-out',
                        position: 'sticky',
                    }}
                />
                <Box sx={{ maxHeight: '490px', overflow: 'auto' }}>
                    <NotificationList />
                </Box>
                <Box
                    sx={{
                        boxShadow: '0px 8px 24px 1px rgba(0, 0, 0)',
                        transition: 'box-shadow 150ms ease-in-out',
                        position: 'sticky',
                    }}
                />
            </Menu>
        </>
    )
}

const NotificationList = () => {
    const dispatch = useAppDispatch()
    const totalCount = useAppSelector(selectTotalCount)
    const [markAsReadApi] = useMarkAsReadMutation()
    const notifications = useAppSelector(selectAllNotifications)
    const [offset, setOffset] = useState(defaultLimit)
    const [getLatestNotificationsApi] = useLazyGetLatestQuery()

    const loadMore = async () => {
        getLatestNotificationsApi({
            offset: offset,
            limit: defaultLimit,
        })
            .unwrap()
            .then((resp) => {
                setOffset(offset + defaultLimit)
                dispatch(
                    receivedNewNotifications({
                        notificationList: resp.notifications,
                        totalCount: resp.totalCount,
                        totalUnreadCount: resp.totalUnreadCount,
                    }),
                )
            })
    }

    const markAsRead = async (notificationId: number) => {
        await markAsReadApi(notificationId).unwrap()
        dispatch(receivedNotificationRead({ notificationId: notificationId }))
    }

    return (
        <>
            {notifications && notifications.length > 0 ? (
                <>
                    {notifications.map((n, index) => {
                        return (
                            <Box key={`notification-${index}`} sx={{ backgroundColor: n.read ? '#FFF' : '#2a9d8f20' }}>
                                {index > 0 && <Divider />}
                                <Box>
                                    <NotificationComponent notification={n} markAsRead={markAsRead} />
                                </Box>
                            </Box>
                        )
                    })}
                    {totalCount > offset && (
                        <>
                            <Divider />
                            <Box
                                sx={{ display: 'flex', justifyContent: 'center', pt: 2, pb: 1, cursor: 'pointer' }}
                                onClick={loadMore}
                            >
                                <Typography variant={'body2'} sx={{ fontWeight: 'bold' }}>
                                    &#709; View 20 more notifications
                                </Typography>
                            </Box>
                        </>
                    )}
                </>
            ) : (
                <Box sx={{ p: 1, pl: 2.5, pr: 2.5, width: '450px' }}>
                    <Typography>You don&apos;t have any notifications</Typography>
                </Box>
            )}
        </>
    )
}

const NotificationComponent = ({
    notification,
    markAsRead,
}: {
    notification: Notification
    markAsRead: (notificationId: number) => void
}) => {
    switch (notification.type) {
        case NotificationType.AnalysisResult:
            return <AnalysisResult notification={notification} markAsRead={markAsRead} />
        case NotificationType.ReadmeMention:
            return <ReadmeMention notification={notification} markAsRead={markAsRead} />
        case NotificationType.DuplicateSampleNames:
            return <DuplicateSampleNames notification={notification} markAsRead={markAsRead} />
        case NotificationType.ProjectAccess:
            return <ProjectAccess notification={notification} markAsRead={markAsRead} />
        case NotificationType.SampleMetadataUploadErrors:
            return <SampleMetadataUploadErrors notification={notification} markAsRead={markAsRead} />
        case NotificationType.GeneCoPerturbationResult:
            return <GeneCoPerturbationResult notification={notification} markAsRead={markAsRead} />
        case NotificationType.ExecutionPipelineResult:
            return <PipelineExecutionResult notification={notification} markAsRead={markAsRead} />
        case NotificationType.ExecutionPodBuildResult:
            return <ExecutionPodBuildResult notification={notification} markAsRead={markAsRead} />
        default:
            return <></>
    }
}
