import { store } from '../../../app/store'
import { PipelinesUpdated, ResponseMessage } from '../../../model/webSocketCommands'
import { pipelineApiSlice } from './pipelineApiSlice'

/**
 * Selector for pipeline status updates
 */
export const selectPipelineStatusUpdate = (message: ResponseMessage): PipelinesUpdated | null => {
    return message.pipelinesUpdated
}

/**
 * Update pipeline execution status in the RTK Query cache
 */
export function handlePipelineStatusUpdate(update: PipelinesUpdated) {
    // Hard to update exactly the correct pagination request, so invalidate the correct tags instead.
    store.dispatch(pipelineApiSlice.util.invalidateTags(update.ids.map((id) => ({ type: 'PipelineExecution', id }))))
}
