import { ExecutionPodVersion } from './customModule'
import { Organization, User } from './model'

export enum PipelineStatus {
    Pending = 'pending',
    Running = 'running',
    CantGetStatus = 'cant_get_status',
    ExecutionCompleted = 'execution_completed',
    ExecutionFailed = 'execution_failed',
    ExecutionTerminating = 'execution_terminating',
    ExecutionTerminated = 'execution_terminated',
    PendingRetryPostProcessing = 'pending_retry_post_processing',
    PostProcessing = 'post_processing',
    PostProcessingFailed = 'post_processing_failed',
    PipelineCompleted = 'pipeline_completed',
}

export enum PipelineStageStatus {
    Pending = 'pending',
    Running = 'running',
    Completed = 'completed',
    Failed = 'failed',
    Terminating = 'terminating',
    Terminated = 'terminated',
    Unknown = 'unknown',
}

export type PipelineExecutionStage = {
    id: string
    createdAt: string
    updatedAt: string
    name: string
    organization: Organization
    pipelineExecutionId: string
    stageIndex: number
    isSidecar: boolean
    executionPodVersionId: string | null
    executionPodVersion: ExecutionPodVersion | null
    requirementsDefinitionOverride: Record<string, unknown>
    status: PipelineStageStatus
    statusReason: string
    attempts: number
    startedAt: string | null
    attemptStartedAt: string | null
    attemptCompletedAt: string | null
    pricePerHourUsd: number
}

// Basic information returned in list views
export type PipelineExecutionSummary = {
    id: string
    name: string
    createdAt: string
    updatedAt: string
    organization: Organization
    user: User
    status: PipelineStatus
    statusUpdatedAt: string
    attempts: number
}

// Detailed information including stages
export type PipelineExecution = PipelineExecutionSummary & {
    stages: PipelineExecutionStage[]
}

export type LogEvent = {
    timestamp: string
    message: string
}
