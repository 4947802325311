import { wsSlice } from '../../app/wsSlice'
import {
    handleExecutionPodVersionsUpdated,
    selectExecutionPodVersionsUpdated,
} from './executionpods/executionPodWebSocketListeners'
import { handlePipelineStatusUpdate, selectPipelineStatusUpdate } from './pipeline/pipelineWebSocketListeners'

/**
 * Initialize all custom compute-related WebSocket listeners
 */
export function initCustomComputeWebSocketListeners() {
    // Register handlers with their specific selectors
    wsSlice.injectSelectHandlers(selectExecutionPodVersionsUpdated, [handleExecutionPodVersionsUpdated])

    wsSlice.injectSelectHandlers(selectPipelineStatusUpdate, [handlePipelineStatusUpdate])
}
