import { wsSlice } from '../../../app/wsSlice'
import { LightExecCommand } from '../../../model/model'
import { receivedAddRunningCommandList, receivedRemoveRunningCommand } from './analysisSlice'

/**
 * Initialize WebSocket handlers for analysis updates
 */
export function initAnalysisWebSocketListeners() {
    wsSlice.injectSelectHandlers(
        (m) => m.analysisCommandFinished,
        [
            (p, dispatch) => {
                dispatch(
                    receivedRemoveRunningCommand({
                        analysisId: p.analysisId,
                        execCommandId: p.commandId,
                    }),
                )
            },
        ],
    )

    wsSlice.injectSelectHandlers(
        (m) => m.runningCommands,
        [
            (p, dispatch) => {
                const splitByAnalysis: Record<number, LightExecCommand[]> = p.reduce(
                    (acc: Record<number, LightExecCommand[]>, obj) => {
                        if (!acc[obj.analysisId]) {
                            acc[obj.analysisId] = []
                        }
                        acc[obj.analysisId].push(obj)
                        return acc
                    },
                    {},
                )
                Object.entries(splitByAnalysis).forEach((e) => {
                    dispatch(
                        receivedAddRunningCommandList({
                            analysisId: parseInt(e[0]),
                            components: e[1],
                        }),
                    )
                })
            },
        ],
    )
}
