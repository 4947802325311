import { Delete } from '@mui/icons-material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, Divider, Tooltip } from '@mui/material'
import {
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarExport,
    ToolbarPropsOverrides,
} from '@mui/x-data-grid-premium'
import { useTranslation } from 'react-i18next'

function RefreshButton({ onClick }: { onClick: () => void }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Reload the metadata field list.'} arrow>
            <span>
                <Button startIcon={<RefreshIcon />} onClick={onClick} size={'small'}>
                    {t('refresh')}
                </Button>
            </span>
        </Tooltip>
    )
}

function CreateButton({ onClick }: { onClick: () => void }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Create a new metadata field.'} arrow>
            <span>
                <Button startIcon={<AddCircleOutlineIcon />} onClick={onClick} size={'small'}>
                    {t('create')}
                </Button>
            </span>
        </Tooltip>
    )
}

function UpdateButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Edit the selected metadata field.'} arrow>
            <span>
                <Button startIcon={<EditIcon />} onClick={onClick} size={'small'} disabled={disabled}>
                    {t('edit')}
                </Button>
            </span>
        </Tooltip>
    )
}

function DeleteButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
    const { t } = useTranslation()

    return (
        <Tooltip title={'Permanently delete the selected metadata field.'} arrow>
            <span>
                <Button startIcon={<Delete />} onClick={onClick} size={'small'} disabled={disabled} color={'error'}>
                    {t('delete')}
                </Button>
            </span>
        </Tooltip>
    )
}

declare module '@mui/x-data-grid-premium' {
    interface ToolbarPropsOverrides {
        refreshCallback: () => void
        createCallback: () => void
        updateCallback: () => void
        deleteCallback: () => void
        disableUpdate: boolean
        disableDelete: boolean
    }
}

function MetadataFieldGridToolbar({
    refreshCallback,
    createCallback,
    updateCallback,
    deleteCallback,
    disableUpdate,
    disableDelete,
}: ToolbarPropsOverrides) {
    return (
        <GridToolbarContainer>
            <GridToolbarFilterButton slotProps={{ tooltip: { arrow: true } }} />
            <GridToolbarExport
                slotProps={{ tooltip: { arrow: true } }}
                csvOptions={{
                    fileName: 'panomics_metadata_fields',
                }}
                excelOptions={{
                    fileName: 'panomics_metadata_fields',
                }}
                printOptions={{
                    disableToolbarButton: true,
                }}
            />
            <RefreshButton onClick={refreshCallback} />
            <Divider orientation='vertical' variant='middle' flexItem />
            <CreateButton onClick={createCallback} />
            <UpdateButton onClick={updateCallback} disabled={disableUpdate} />
            <Divider orientation='vertical' variant='middle' flexItem />
            <DeleteButton onClick={deleteCallback} disabled={disableDelete} />
        </GridToolbarContainer>
    )
}

export default MetadataFieldGridToolbar
