import { wsSlice } from '../../../app/wsSlice'
import { receivedReadmeVersion } from './readmeSlice'

/**
 * Initialize WebSocket handlers for readme version updates
 */
export function initReadmeWebSocketListeners() {
    wsSlice.injectSelectHandlers(
        (m) => m.projectReadmeVersion,
        [
            (p, dispatch) => {
                dispatch(
                    receivedReadmeVersion({
                        readmeVersion: p,
                    }),
                )
            },
        ],
    )
}
