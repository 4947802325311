import { wsSlice } from '../../app/wsSlice'
import { receivedNotification } from './notificationSlice'

/**
 * Initialize WebSocket handlers for user notifications
 */
export function initNotificationWebSocketListeners() {
    wsSlice.injectSelectHandlers(
        (m) => m.userNotification,
        [
            (p, dispatch) => {
                dispatch(
                    receivedNotification({
                        notification: p,
                    }),
                )
            },
        ],
    )
}
