import { AppDispatch } from '../../../../app/store'
import { wsSlice } from '../../../../app/wsSlice'
import {
    MicroarrayAnalysisResultData,
    NCounterAnalysisResultData,
    ProteomicsAnalysisResultData,
    RnaSeqAnalysisResultData,
    ScRnaSeqAnalysisResultData,
} from '../../../../model/analysisCommands'
import {
    Component,
    ComputeStatistics,
    DeleteBarcodes,
    DifferentialExpression,
    DifferentialPathwayActivity,
    GeneSetEnrichment,
    ImportDifferentialExpression,
    PathwayActivityInference,
    PlotGeneExpression,
    PlotMarkerGenes,
    PlotPathwayActivityScores,
    RemoveSamples,
    RnaSeqNormalize,
    RnaSeqQcGeneSelection,
    ScRnaSeqAnnotateCells,
    ScRnaSeqCellComposition,
    ScRnaSeqCellTypePrediction,
    ScRnaSeqClustering,
    ScRnaSeqNormalize,
    ScRnaSeqQcCellSelection,
    ScRnaSeqQcGeneSelection,
    Umap,
    WeightedGeneCoExpressionNetworkAnalysis,
} from '../../../../model/analysisComponents'
import {
    DoneCommand,
    FailedCommand,
    MicroarrayWorkflow,
    NCounterWorkflow,
    OlinkProteomicsWorkflow,
    RnaSeqWorkflow,
    ScRnaSeqWorkflow,
    TerminatedCommand,
} from '../../../../model/model'
import { AnalysisCommandFinished } from '../../../../model/webSocketCommands'
import { receivedAppMessage } from '../../../dashboard/appMessageSlice'
import { reloadObsDataFrame } from '../modules/common/observations/reloadObservations'
import {
    receivedCellCompositionPreviews,
    receivedComputeStatisticsPreviews,
    receivedDatasetGenes,
    receivedDifferentialExpressionPreviews,
    receivedDifferentialPathwayActivityPreviews,
    receivedGeneSetEnrichmentPreviews,
    receivedNormalizeResults,
    receivedPathwayActivityInferenceResults,
    receivedPlotMarkerGenesPreviews,
    receivedUmapComputed,
    receivedVarCsvUrl,
    receivedWGCNAPreviews,
} from './analysisResultsSlice'

/**
 * Initialize WebSocket handlers for analysis results
 */
export function initAnalysisResultsWebSocketListeners() {
    wsSlice.injectSelectHandlers<AnalysisCommandFinished>(
        (m) => m.analysisCommandFinished,
        [
            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    DifferentialExpression,
                    (analysisId, data) =>
                        dispatch(
                            receivedDifferentialExpressionPreviews({
                                analysisId: analysisId,
                                differentialExpressionPreviews: data?.differentialExpressionPreviews,
                            }),
                        ),
                    'Differential expression analysis completed successfully.',
                    'Differential expression analysis failed. Please try again or contact support.',
                    'Differential expression analysis terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/deg',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    DifferentialPathwayActivity,
                    (analysisId, data) =>
                        dispatch(
                            receivedDifferentialPathwayActivityPreviews({
                                analysisId: analysisId,
                                differentialPathwayActivityPreviews: data?.differentialPathwayActivityPreviews,
                            }),
                        ),
                    'Differential pathway activity analysis completed successfully.',
                    'Differential pathway activity analysis failed. Please try again or contact support.',
                    'Differential pathway activity analysis terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/dap',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ImportDifferentialExpression,
                    (analysisId, data) =>
                        dispatch(
                            receivedDifferentialExpressionPreviews({
                                analysisId: analysisId,
                                differentialExpressionPreviews: data?.differentialExpressionPreviews,
                            }),
                        ),
                    'Differential expression analysis imported successfully.',
                    'Differential expression analysis import failed. Please try again or contact support.',
                    'Differential expression analysis import terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/deg',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ScRnaSeqAnnotateCells,
                    (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                    'Cell annotation completed successfully.',
                    'Cell annotation failed. Please try again or contact support.',
                    'Cell annotation terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/cell-explorer',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    Umap,
                    (analysisId, data) => {
                        reloadObsDataFrame(analysisId, data, dispatch)
                        dispatch(receivedUmapComputed({ analysisId, umapComputed: data.umapComputed }))
                    },
                    'UMAP computation completed successfully.',
                    'UMAP computation failed. Please try again or contact support.',
                    'UMAP computation terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/{explorerFlavor}',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    DeleteBarcodes,
                    (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                    'Barcode deletion completed successfully',
                    'Barcode deletion failed. Please try again or contact support.',
                    'Barcode deletion terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/{explorerFlavor}',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    RemoveSamples,
                    (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                    'Sample removal completed successfully',
                    'Sample removal failed. Please try again or contact support.',
                    'Sample removal terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/{explorerFlavor}',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ScRnaSeqQcCellSelection,
                    (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                    'Cell filtering completed successfully.',
                    'Cell filtering failed. Please try again or contact support.',
                    'Cell filtering terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/qc',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ScRnaSeqQcGeneSelection,
                    (analysisId, data) => {
                        dispatch(receivedVarCsvUrl({ analysisId, varCsvUrl: data.varCsvUrl }))
                        dispatch(receivedDatasetGenes({ analysisId, datasetGenes: data.datasetGenes }))
                    },
                    'Gene filtering completed successfully.',
                    'Gene filtering failed. Please try again or contact support.',
                    'Gene filtering terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/qc',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    RnaSeqQcGeneSelection,
                    (analysisId, data) => {
                        dispatch(receivedVarCsvUrl({ analysisId, varCsvUrl: data.varCsvUrl }))
                        dispatch(receivedDatasetGenes({ analysisId, datasetGenes: data.datasetGenes }))
                    },
                    'Gene filtering completed successfully.',
                    'Gene filtering failed. Please try again or contact support.',
                    'Gene filtering terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/qc',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    PlotPathwayActivityScores,
                    (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                    'Pathway activity score plotting completed successfully.',
                    'Pathway activity score plotting failed. Please try again or contact support.',
                    'Pathway activity score plotting terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/{explorerFlavor}',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    PathwayActivityInference,
                    (analysisId, data) =>
                        dispatch(
                            receivedPathwayActivityInferenceResults({
                                analysisId: analysisId,
                                pathwayActivityInferenceResultsCsvUrl: data?.pathwayActivityInferenceResultsCsvUrl,
                            }),
                        ),
                    'Pathway activity inference completed successfully.',
                    'Pathway activity inference failed. Please try again or contact support.',
                    'Pathway activity inference terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/{explorerFlavor}',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ScRnaSeqNormalize,
                    (analysisId, data) =>
                        dispatch(
                            receivedNormalizeResults({
                                analysisId: analysisId,
                                normalized: data.normalized,
                            }),
                        ),
                    'Normalization completed successfully.',
                    'Normalization failed. Please try again or contact support.',
                    'Normalization terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/cell-explorer',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    RnaSeqNormalize,
                    (analysisId, data) =>
                        dispatch(
                            receivedNormalizeResults({
                                analysisId: analysisId,
                                normalized: data.normalized,
                            }),
                        ),
                    'Normalization completed successfully.',
                    'Normalization failed. Please try again or contact support.',
                    'Normalization terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/sample-explorer',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    PlotMarkerGenes,
                    (analysisId, data) =>
                        dispatch(
                            receivedPlotMarkerGenesPreviews({
                                analysisId: analysisId,
                                plotMarkerGenesPreviews: data?.plotMarkerGenesPreviews,
                            }),
                        ),
                    'Marker gene plotting completed successfully.',
                    'Marker gene plotting failed. Please try again or contact support.',
                    'Marker gene plotting terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/marker-genes',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ComputeStatistics,
                    (analysisId, data) =>
                        dispatch(
                            receivedComputeStatisticsPreviews({
                                analysisId: analysisId,
                                computeStatisticsPreviews: data?.computeStatisticsPreviews,
                            }),
                        ),
                    'Compute statistics completed successfully.',
                    'Compute statistics failed. Please try again or contact support.',
                    'Compute statistics terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/compute-statistics',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    GeneSetEnrichment,
                    (analysisId, data) =>
                        dispatch(
                            receivedGeneSetEnrichmentPreviews({
                                analysisId: analysisId,
                                geneSetEnrichmentPreviews: data?.geneSetEnrichmentPreviews,
                            }),
                        ),
                    'Gene set enrichment completed successfully.',
                    'Gene set enrichment failed. Please try again or contact support.',
                    'Gene set enrichment terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/gsea',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ScRnaSeqClustering,
                    (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                    'Clustering completed successfully.',
                    'Clustering failed. Please try again or contact support.',
                    'Clustering terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/cell-explorer',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ScRnaSeqCellTypePrediction,
                    (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                    'Cell type prediction completed successfully.',
                    'Cell type prediction failed. Please try again or contact support.',
                    'Cell type prediction terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/cell-explorer',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    PlotGeneExpression,
                    (analysisId, data) => reloadObsDataFrame(analysisId, data, dispatch),
                    'Gene expression plotting completed successfully.',
                    'Gene expression plotting failed. Please try again or contact support.',
                    'Gene expression plotting terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/{explorerFlavor}',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    ScRnaSeqCellComposition,
                    (analysisId, data) =>
                        dispatch(
                            receivedCellCompositionPreviews({
                                analysisId: analysisId,
                                cellCompositionPreviews: (data as ScRnaSeqAnalysisResultData)?.cellCompositionPreviews,
                            }),
                        ),
                    'Cell compositional analysis completed successfully.',
                    'Cell compositional analysis failed. Please try again or contact support.',
                    'Cell compositional analysis terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/cell-composition',
                ),

            (payload, dispatch) =>
                payloadHelper(
                    payload,
                    dispatch,
                    WeightedGeneCoExpressionNetworkAnalysis,
                    (analysisId, data) =>
                        dispatch(
                            receivedWGCNAPreviews({
                                analysisId: analysisId,
                                wgcnaPreviews: (
                                    data as
                                        | RnaSeqAnalysisResultData
                                        | MicroarrayAnalysisResultData
                                        | NCounterAnalysisResultData
                                )?.wgcnaPreviews,
                            }),
                        ),
                    'WGCNA completed successfully.',
                    'WGCNA failed. Please try again or contact support.',
                    'WGCNA terminated due to runtime session duration settings. Recreate a runtime with a longer session duration and relaunch your command.',
                    '/analysis/{analysisType}/{analysisId}/wgcna',
                ),
        ],
    )
}

function payloadHelper(
    payload: AnalysisCommandFinished,
    dispatch: AppDispatch,
    component: Component,
    onSuccess: (
        analysisId: number,
        data:
            | ScRnaSeqAnalysisResultData
            | RnaSeqAnalysisResultData
            | MicroarrayAnalysisResultData
            | ProteomicsAnalysisResultData
            | NCounterAnalysisResultData,
    ) => void,
    successMsg: string,
    failMsg: string,
    terminatedMsg: string,
    navigationPath?: string,
): void {
    if (payload.component !== component) {
        return
    }

    let data:
        | ScRnaSeqAnalysisResultData
        | RnaSeqAnalysisResultData
        | MicroarrayAnalysisResultData
        | ProteomicsAnalysisResultData
        | NCounterAnalysisResultData
        | undefined
    let navigationAnalysisType = ''
    let explorerFlavor = ''

    switch (payload.analysis.analysisWorkflow) {
        case ScRnaSeqWorkflow:
            navigationAnalysisType = 'scrnaseq'
            explorerFlavor = 'cell-explorer'
            data = payload.scRnaSeqAnalysisResultData
            break
        case RnaSeqWorkflow:
            navigationAnalysisType = 'rnaseq'
            explorerFlavor = 'sample-explorer'
            data = payload.rnaSeqAnalysisResultData
            break
        case MicroarrayWorkflow:
            navigationAnalysisType = 'microarray'
            explorerFlavor = 'sample-explorer'
            data = payload.microarrayAnalysisResultData
            break
        case OlinkProteomicsWorkflow:
            navigationAnalysisType = 'prot'
            explorerFlavor = 'sample-explorer'
            data = payload.proteomicsAnalysisResultData
            break
        case NCounterWorkflow:
            navigationAnalysisType = 'ncounter'
            explorerFlavor = 'sample-explorer'
            data = payload.nCounterAnalysisResultData
            break
    }

    if (navigationPath) {
        navigationPath = navigationPath.replaceAll('{analysisId}', payload.analysisId.toString())
        navigationPath = navigationPath.replaceAll('{analysisType}', navigationAnalysisType)
        navigationPath = navigationPath.replaceAll('{explorerFlavor}', explorerFlavor)
    }

    if (payload.status === FailedCommand) {
        dispatch(
            receivedAppMessage({
                type: 'error',
                message: failMsg,
                navigationPath,
            }),
        )
        return
    }
    if (payload.status === TerminatedCommand) {
        dispatch(
            receivedAppMessage({
                type: 'warning',
                message: terminatedMsg,
            }),
        )
        return
    }

    if (payload.status !== DoneCommand) {
        return
    }
    if (payload.key) {
        const searchParams = new URLSearchParams()
        searchParams.set('key', payload.key)
        navigationPath += `?${searchParams.toString()}`
    }
    dispatch(
        receivedAppMessage({
            type: 'success',
            message: successMsg,
            navigationPath,
        }),
    )

    if (!data) {
        return
    }
    onSuccess(payload.analysisId, data)
}
