import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExecutionPipelineResultNotificationProps } from '../../../model/model'
import { PipelineStatus } from '../../../model/pipeline'
import NotificationContent, { NotificationParams } from './NotificationContent'

export default function PipelineExecutionResult({ notification, markAsRead }: NotificationParams) {
    const navigate = useNavigate()

    const properties = useMemo((): ExecutionPipelineResultNotificationProps => {
        return notification.properties as unknown as ExecutionPipelineResultNotificationProps
    }, [notification])

    const icon = useMemo(() => {
        switch (properties.status) {
            case PipelineStatus.PipelineCompleted:
                return <CheckIcon color={'primary'} />
            case PipelineStatus.ExecutionFailed:
            case PipelineStatus.PostProcessingFailed:
                return <ErrorOutlineIcon color={'error'} />
            case PipelineStatus.ExecutionTerminated:
                return <CancelPresentationIcon color={'warning'} />
            default:
                return <InfoIcon color={'primary'} />
        }
    }, [properties])

    const getStatusTitle = () => {
        switch (properties.status) {
            case PipelineStatus.PipelineCompleted:
                return 'Pipeline Execution Completed'
            case PipelineStatus.ExecutionFailed:
            case PipelineStatus.PostProcessingFailed:
                return 'Pipeline Execution Failed'
            case PipelineStatus.ExecutionTerminated:
                return 'Pipeline Execution Terminated'
            default:
                return `Pipeline Execution Status: ${properties.status}`
        }
    }

    return (
        <>
            <NotificationContent
                notification={notification}
                onClick={() => {
                    if (!notification.read) {
                        markAsRead(notification.id)
                    }

                    navigate(`/pipeline/executions?pipelineId=${properties.executionPipelineId}`)
                }}
                icon={icon}
                title={<>{getStatusTitle()}</>}
            >
                <Box>
                    <Typography variant={'body2'}>
                        {properties.mainStageNames && properties.mainStageNames.length > 0 ? (
                            <>
                                Pipeline <b>{properties.mainStageNames.join(' → ')}</b> finished.
                            </>
                        ) : (
                            <>
                                Pipeline <b>{properties.executionPipelineId}</b> finished.
                            </>
                        )}
                    </Typography>
                    {properties.errorMessage && (
                        <Typography variant={'body2'} color='error' sx={{ mt: 1 }}>
                            Error: {properties.errorMessage}
                        </Typography>
                    )}
                </Box>
            </NotificationContent>
        </>
    )
}
