import { User } from './model'

export enum ExecutionPodVisibility {
    Private = 'private',
    Public = 'public',
}

export type ExecutionPod = {
    id: string
    createdAt: string
    updatedAt: string
    user: User
    name: string
    description: string
    tags: string[]
    visibility: ExecutionPodVisibility
    repositoryUrl: string
}

// the access type is for requesting user with respect to the execution pod
export type ExecutionPodWithAccess = ExecutionPod & {
    accessType: ExecutionPodAccessType
}

export enum ExecutionPodAccessType {
    Administrator = 'administrator',
    Contributor = 'contributor',
    User = 'user',
}

export type ExecutionPodAccess = {
    createdAt: string
    updatedAt: string
    user: User
    executionPodId: string
    accessType: ExecutionPodAccessType
}

export type RepositoryTag = {
    name: string
    commitHash: string
}

export enum ExecutionPodVersionStatus {
    Draft = 'draft',
    Pending = 'pending',
    Building = 'building',
    Failed = 'failed',
    Ready = 'ready',
    Archived = 'archived',
}

export type ExecutionPodVersion = {
    id: string
    createdAt: string
    updatedAt: string
    name: string
    user: User
    executionPodId: string

    commitHash: string
    tagName: string
    tagAuthor: string | null
    tagMessage: string | null
    tagDate: string | null

    definition: Record<string, object>
    status: ExecutionPodVersionStatus
    image: string | null
    failReason: string | null
}

export type CustomModule = {
    id: number
    createdAt: string
    updatedAt: string
    name: string
    description: string
    iconKey: string
}

export enum CustomModuleAccessType {
    Administrator = 'administrator',
    Contributor = 'contributor',
    User = 'user',
}

export type CustomModuleAccess = {
    createdAt: string
    updatedAt: string
    userId: number
    customModuleId: number
    accessType: CustomModuleAccessType
}

export enum CustomModuleVersionStatus {
    Draft = 'draft',
    Published = 'published',
}

export type CustomModuleVersion = {
    id: number
    createdAt: string
    updatedAt: string
    customModule: CustomModule
    executionPodVersion: ExecutionPodVersion
    version: string
    status: CustomModuleVersionStatus
    overviewTileDefinition: Record<string, object>
    overviewGridDefinition: Record<string, object>
    resultDetailsDefinition: Record<string, object>
}
