import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import BiotechIcon from '@mui/icons-material/Biotech'
import BlurLinearIcon from '@mui/icons-material/BlurLinear'
import DifferenceIcon from '@mui/icons-material/Difference'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import FlareIcon from '@mui/icons-material/Flare'
import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import SearchIcon from '@mui/icons-material/Search'
import SsidChartIcon from '@mui/icons-material/SsidChart'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import { Alert, CircularProgress, Link, MenuItem, Snackbar, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import * as React from 'react'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import NetworkIcon from '../../../assets/icons/Network'
import JobNotRunningIcon from '../../../assets/images/clock.png'
import JobRunningIcon from '../../../assets/images/energy.gif'
import {
    Analysis,
    InProgressCommand,
    LightExecCommand,
    MicroarrayWorkflow,
    RnaSeqWorkflow,
    ScRnaSeqWorkflow,
    OlinkProteomicsWorkflow,
    NCounterWorkflow,
} from '../../../model/model'
import {
    clearAppMessage,
    receivedAppMessage,
    selectAppMessage,
    selectAppMessageOpen,
    selectAppMessageType,
    selectAppNavigationPath,
} from '../../dashboard/appMessageSlice'
import AnalysisNavBar from './AnalysisNavBar'
import {
    receivedAddRunningCommandList,
    receivedAnalysis,
    receivedRemoveRunningCommand,
    selectRunningCommands,
} from './analysisSlice'
import { receivedAnalysis as resultSliceReceivedAnalysis, resetResults } from './results/analysisResultsSlice'
import {
    useCancelCommandMutation,
    useGetAnalysisQuery,
    useLazyGetRunningComponentsQuery,
} from './workbenchAnalysisApiSlice'
import { useLazyGetWorkbenchQuery } from '../workbenchApiSlice'
import { receivedWorkbench } from '../workbenchSlice'
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import { SvgIconComponent } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'

dayjs.extend(relativeTime)
dayjs.extend(utc)

// New type definitions and configurations
type ModuleConfig = {
    path: string
    icon: SvgIconComponent | React.ComponentType<SvgIconProps>
    label: string
    fontSize?: string
}

type WorkflowConfig = {
    baseUrl: string
    modules: ModuleConfig[]
}

const moduleDefinitions: Record<string, ModuleConfig> = {
    qc: {
        path: 'qc',
        icon: BiotechIcon,
        label: 'Quality Control',
    },
    computeStats: {
        path: 'compute-statistics',
        icon: AutoGraphIcon,
        label: 'Compute Statistics',
    },
    markerGenes: {
        path: 'marker-genes',
        icon: BlurLinearIcon,
        label: 'Marker Genes',
    },
    dataSlice: {
        path: 'dataslice',
        icon: DonutLargeIcon,
        label: 'Data Slices',
    },
    deg: {
        path: 'deg',
        icon: DifferenceIcon,
        label: 'Differential Expression',
    },
    dap: {
        path: 'dap',
        icon: SsidChartIcon,
        label: 'Differential Pathway Activity',
        fontSize: '0.8em',
    },
    gsea: {
        path: 'gsea',
        icon: FlareIcon,
        label: 'Gene Set Enrichment',
    },
    gex: {
        path: 'gex',
        icon: LeaderboardIcon,
        label: 'Gene Expression',
    },
    pex: {
        path: 'pex',
        icon: LeaderboardIcon,
        label: 'Protein Expression',
    },
    cellExplorer: {
        path: 'cell-explorer',
        icon: SearchIcon,
        label: 'Cell Explorer',
    },
    sampleExplorer: {
        path: 'sample-explorer',
        icon: SearchIcon,
        label: 'Sample Explorer',
    },
    cellComposition: {
        path: 'cell-composition',
        icon: StackedBarChartIcon,
        label: 'Cell Composition',
    },
    wgcna: {
        path: 'wgcna',
        icon: NetworkIcon,
        label: 'WGCNA',
    },
}

const workflowConfigs: Record<string, WorkflowConfig> = {
    [ScRnaSeqWorkflow]: {
        baseUrl: 'scrnaseq',
        modules: [
            'qc',
            'cellExplorer',
            'computeStats',
            'gex',
            'cellComposition',
            'markerGenes',
            'dataSlice',
            'deg',
            'dap',
            'gsea',
        ].map((key) => moduleDefinitions[key]),
    },
    [RnaSeqWorkflow]: {
        baseUrl: 'rnaseq',
        modules: [
            'qc',
            'sampleExplorer',
            'computeStats',
            'gex',
            'markerGenes',
            'dataSlice',
            'deg',
            'dap',
            'gsea',
            'wgcna',
        ].map((key) => moduleDefinitions[key]),
    },
    [MicroarrayWorkflow]: {
        baseUrl: 'microarray',
        modules: [
            'qc',
            'sampleExplorer',
            'computeStats',
            'gex',
            'markerGenes',
            'dataSlice',
            'deg',
            'dap',
            'gsea',
            'wgcna',
        ].map((key) => moduleDefinitions[key]),
    },
    [OlinkProteomicsWorkflow]: {
        baseUrl: 'prot',
        modules: [
            'qc',
            'sampleExplorer',
            'computeStats',
            'pex',
            'markerGenes',
            'dataSlice',
            'deg',
            'dap',
            'gsea',
            'wgcna',
        ].map((key) => moduleDefinitions[key]),
    },
    [NCounterWorkflow]: {
        baseUrl: 'ncounter',
        modules: [
            'qc',
            'sampleExplorer',
            'computeStats',
            'gex',
            'markerGenes',
            'dataSlice',
            'deg',
            'dap',
            'gsea',
            'wgcna',
        ].map((key) => moduleDefinitions[key]),
    },
}

// New generic component for analysis modules
const AnalysisModules = ({
    analysis,
    getMenuItemColor,
}: {
    analysis: Analysis
    getMenuItemColor: (v: string) => string
}) => {
    const navigate = useNavigate()
    const config = workflowConfigs[analysis.analysisWorkflow]

    if (!config) return null

    return (
        <>
            {config.modules.map((module, index) => (
                <React.Fragment key={module.path}>
                    <Link
                        sx={index === 0 ? { pt: 0 } : undefined}
                        onClick={() => {
                            navigate(`/analysis/${config.baseUrl}/${analysis.id}/${module.path}`)
                        }}
                    >
                        <module.icon sx={{ color: getMenuItemColor(module.path) }} />
                        <Typography
                            variant='body2'
                            sx={{
                                color: getMenuItemColor(module.path),
                                fontSize: module.fontSize,
                            }}
                        >
                            {module.label}
                        </Typography>
                    </Link>
                    {index < config.modules.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </>
    )
}

export default function AnalysisDashboard({ children }: { children?: ReactNode }) {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { aid } = useParams()
    if (!aid) {
        return <>...</>
    }
    const appMessageOpen = useAppSelector(selectAppMessageOpen)
    const appMessageType = useAppSelector(selectAppMessageType)
    const appMessage = useAppSelector(selectAppMessage)
    const appMessageNavigationPath = useAppSelector(selectAppNavigationPath)

    const [getWorkbench] = useLazyGetWorkbenchQuery()
    const { data: analysis } = useGetAnalysisQuery(parseInt(aid), { refetchOnMountOrArgChange: true })
    const [getRunningCommandsApi] = useLazyGetRunningComponentsQuery()

    useEffect(() => {
        if (analysis) {
            dispatch(
                receivedAnalysis({
                    analysis,
                }),
            )

            dispatch(
                resultSliceReceivedAnalysis({
                    analysis,
                }),
            )

            getRunningCommandsApi(analysis.id)
                .unwrap()
                .then((runningCommands) => {
                    dispatch(
                        receivedAddRunningCommandList({
                            analysisId: analysis.id,
                            components: runningCommands,
                        }),
                    )
                })
                .catch(() => {
                    dispatch(
                        receivedAppMessage({
                            type: 'error',
                            message: 'Error loading running commands.',
                        }),
                    )
                })

            getWorkbench(analysis.project.id)
                .unwrap()
                .then((workbench) => {
                    dispatch(
                        receivedWorkbench({
                            project: workbench.project,
                            projectAccessList: workbench.projectAccessList,
                            analysisList: workbench.analysisList,
                        }),
                    )
                })

            return () => {
                dispatch(resetResults())
            }
        }

        return () => {}
    }, [analysis, dispatch])

    const runningCommands = useAppSelector(selectRunningCommands)

    const tail = useMemo(() => {
        if (!analysis) {
            return ''
        }
        switch (analysis.analysisWorkflow) {
            case ScRnaSeqWorkflow:
                return window.location.pathname.replaceAll(`/analysis/scrnaseq/${analysis?.id}/`, '')
            case RnaSeqWorkflow:
                return window.location.pathname.replaceAll(`/analysis/rnaseq/${analysis?.id}/`, '')
            case MicroarrayWorkflow:
                return window.location.pathname.replaceAll(`/analysis/microarray/${analysis?.id}/`, '')
            case OlinkProteomicsWorkflow:
                return window.location.pathname.replaceAll(`/analysis/prot/${analysis?.id}/`, '')
            case NCounterWorkflow:
                return window.location.pathname.replaceAll(`/analysis/ncounter/${analysis?.id}/`, '')
            default:
                return ''
        }
    }, [analysis?.analysisWorkflow, window.location.pathname])

    const getMenuItemColor = (page: string): string => {
        return tail == page ? '#2a9d8f!important' : '#264653'
    }

    return (
        <>
            {analysis != null ? (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', minWidth: '1280px' }}>
                    <CssBaseline />
                    <AnalysisNavBar analysis={analysis} />
                    <Box sx={{ display: 'flex', width: '100%', height: 'calc(100vh - 60px)' }}>
                        <Box
                            sx={{
                                width: '120px',
                                borderRight: '1px solid #DDD',
                                height: '100%',
                                overflowX: 'hidden',
                                overflowY: 'auto',
                            }}
                        >
                            <Box sx={{ height: '100%' }}>
                                <Stack
                                    sx={{
                                        pr: 2,
                                        pl: 2,
                                        '& .MuiLink-root': {
                                            textDecoration: 'none',
                                            textAlign: 'center',
                                            mb: 2,
                                            pt: 2,
                                            color: '#264653',
                                        },
                                        '& .MuiLink-root:hover, & .MuiLink-root:hover .MuiTypography-root, & .MuiLink-root:hover .MuiSvgIcon-root':
                                            {
                                                color: '#2a9d8f',
                                            },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '1.6em',
                                            mb: '2px',
                                        },
                                        height: 'calc(100vh - 120px)',
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                    }}
                                >
                                    <AnalysisModules analysis={analysis} getMenuItemColor={getMenuItemColor} />
                                </Stack>
                                <JobQueue runningCommands={runningCommands} />
                            </Box>
                        </Box>
                        <Box sx={{ width: 'calc(100% - 120px)' }}>{children ? <>{children}</> : <Outlet />}</Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: 'calc(100vh - 112px)',
                    }}
                >
                    <Stack
                        sx={{
                            '& .MuiBox-root': {
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2,
                            },
                            '& .MuiBox-root .MuiTypography-root': {
                                ml: 1,
                            },
                        }}
                    >
                        <Box>
                            <CircularProgress size={20} />
                            <Typography>Downloading analysis data</Typography>
                        </Box>
                    </Stack>
                </Box>
            )}
            <Snackbar open={appMessageOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={() => {
                        dispatch(clearAppMessage())
                    }}
                    severity={appMessageType}
                    sx={{ width: '100%' }}
                >
                    {appMessage}
                    {appMessageNavigationPath && (
                        <Link
                            onClick={() => {
                                navigate(appMessageNavigationPath)
                                dispatch(clearAppMessage())
                            }}
                            sx={{ ml: 0.5 }}
                        >
                            View result
                        </Link>
                    )}
                </Alert>
            </Snackbar>
        </>
    )
}

const JobQueue = ({ runningCommands }: { runningCommands: LightExecCommand[] }) => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const [cancelCommandApi] = useCancelCommandMutation()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const cancelCommand = async (analysisId: number, commandId: number) => {
        await cancelCommandApi(commandId).unwrap()
        dispatch(
            receivedRemoveRunningCommand({
                analysisId: analysisId,
                execCommandId: commandId,
            }),
        )
        dispatch(
            receivedAppMessage({
                type: 'success',
                message: 'Your job was successfully canceled.',
            }),
        )
    }

    return (
        <>
            <Box
                onClick={handleClick}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderTop: '1px solid #DDD',
                    height: '60px',
                    pl: 2,
                    pr: 2,
                    cursor: 'pointer',
                }}
            >
                {runningCommands.length > 0 ? (
                    <>
                        <Box component='img' src={JobRunningIcon} sx={{ width: 36, pr: 1 }} alt='' />
                    </>
                ) : (
                    <Box component='img' src={JobNotRunningIcon} sx={{ width: 32, pr: 1 }} alt='' />
                )}
                <Typography>
                    {runningCommands.length} job{runningCommands.length == 1 ? '' : 's'}
                </Typography>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id='job-queue'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                MenuListProps={{
                    dense: true,
                }}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'auto',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 0.5,
                            ml: '112px',
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                left: -5,
                                bottom: 10,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                            '& .MuiMenuItem-root': {
                                flexDirection: 'column',
                                justifyContent: 'left',
                            },
                            '& .MuiMenuItem-root > .MuiBox-root': {
                                display: 'flex',
                                justifyContent: 'left',
                                width: '100%',
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {runningCommands.length > 0 ? (
                    runningCommands.map((c, i) => {
                        return (
                            <MenuItem key={`running-command-${i}`}>
                                <Box>
                                    <FiberManualRecordIcon
                                        sx={{
                                            width: '10px',
                                            color: c.status == InProgressCommand ? '#2a9d8f' : '#f4a261',
                                            mr: 1,
                                        }}
                                    />
                                    <Typography>{t(c.component)}</Typography>
                                </Box>
                                {c.status == InProgressCommand ? (
                                    <Typography variant={'body2'} sx={{ width: '100%', pl: 2 }}>
                                        Running for:&nbsp;
                                        <span style={{ fontStyle: 'italic' }}>
                                            {dayjs(c.startedAt.toNumber()).fromNow(true)}
                                        </span>
                                    </Typography>
                                ) : (
                                    <Box sx={{ justifyContent: 'space-between' }}>
                                        <Typography
                                            variant={'body2'}
                                            sx={{ width: '100%', pl: 2, pr: 2, fontStyle: 'italic' }}
                                        >
                                            Queued
                                        </Typography>
                                        {i > 0 && (
                                            <Link
                                                color={'error'}
                                                onClick={(e) => {
                                                    void cancelCommand(c.analysisId, c.id)
                                                    e.stopPropagation()
                                                }}
                                            >
                                                Cancel
                                            </Link>
                                        )}
                                    </Box>
                                )}
                            </MenuItem>
                        )
                    })
                ) : (
                    <MenuItem>
                        <Typography>No running or queued jobs</Typography>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}
