import { store } from '../../../app/store'
import { ExecutionPodVersionsUpdated, ResponseMessage } from '../../../model/webSocketCommands'
import { executionPodsApiSlice } from './executionPodsApiSlice'

/**
 * Selector for execution pod version status updates
 */
export const selectExecutionPodVersionsUpdated = (message: ResponseMessage): ExecutionPodVersionsUpdated | null => {
    return message.executionPodVersionsUpdated
}

/**
 * Update execution pod version status in the RTK Query cache
 */
export function handleExecutionPodVersionsUpdated(update: ExecutionPodVersionsUpdated) {
    store.dispatch(
        executionPodsApiSlice.util.invalidateTags(update.ids.map((id) => ({ type: 'ExecutionPodVersion', id }))),
    )
}
