import { wsSlice } from '../../app/wsSlice'
import { receivedAppMessage } from '../dashboard/appMessageSlice'
import { setRuntime } from './runtimeSlice'

/**
 * Initialize WebSocket handlers for runtime status updates
 */
export function initRuntimeWebSocketListeners() {
    wsSlice.injectSelectHandlers(
        (m) => m.runtimeStatusInformation,
        [
            (p, dispatch) => {
                dispatch(
                    setRuntime({
                        id: p.id,
                        connected: p.online,
                        exists: p.exists,
                        proc: p.proc,
                        instanceType: p.instanceType,
                        gpus: p.gpus,
                        vcpus: p.vcpus,
                        memoryGib: p.memoryGib,
                        gpuMemoryGib: p.gpuMemoryGib,
                        createdAt: p.createdAt ?? '',
                        durationHours: p.durationHours,
                        runtimeType: p.runtimeType,
                        volumeSize: p.volumeSize,
                    }),
                )
            },
        ],
    )

    wsSlice.injectSelectHandlers(
        (m) => m.runtimeDeadlineApproaching,
        [
            (p, dispatch) => {
                dispatch(
                    receivedAppMessage({
                        type: 'warning',
                        message: `Your ${p.instanceType} runtime has ${Math.floor(p.timeLeftHours * 60)} minutes left before being automatically terminated. Extend its duration if needed.`,
                    }),
                )
            },
        ],
    )
}
