import ArchiveIcon from '@mui/icons-material/Archive'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExecutionPodVersionStatus } from '../../../model/customModule'
import ExpandableText from '../../common/ExpandableText'
import NotificationContent, { NotificationParams } from './NotificationContent'

interface ExecutionPodBuildResultProps {
    executionPodId: string
    executionPodVersionId: string
    status: ExecutionPodVersionStatus
    failReason: string | null
}

export default function ExecutionPodBuildResult({ notification, markAsRead }: NotificationParams) {
    const navigate = useNavigate()

    const properties = useMemo((): ExecutionPodBuildResultProps => {
        return notification.properties as unknown as ExecutionPodBuildResultProps
    }, [notification])

    const icon = useMemo(() => {
        switch (properties.status) {
            case ExecutionPodVersionStatus.Ready:
                return <CheckIcon color='primary' />
            case ExecutionPodVersionStatus.Failed:
                return <ErrorOutlineIcon color='error' />
            case ExecutionPodVersionStatus.Archived:
                return <ArchiveIcon color='primary' />
            default:
                return <></>
        }
    }, [properties])

    const getStatusTitle = () => {
        switch (properties.status) {
            case ExecutionPodVersionStatus.Ready:
                return 'Execution Pod Build Completed'
            case ExecutionPodVersionStatus.Failed:
                return 'Execution Pod Build Failed'
            case ExecutionPodVersionStatus.Archived:
                return 'Execution Pod Archived'
            default:
                return 'Execution Pod Status Update'
        }
    }

    return (
        <NotificationContent
            notification={notification}
            onClick={() => {
                if (!notification.read) {
                    markAsRead(notification.id)
                }
                navigate(`/execution-pod/${properties.executionPodId}?version=${properties.executionPodVersionId}`)
            }}
            icon={icon}
            title={<>{getStatusTitle()}</>}
        >
            <>
                {properties.status === ExecutionPodVersionStatus.Failed && (
                    <Box>
                        <Typography variant='body2'>Execution Pod version build has failed.</Typography>
                        {properties.failReason && (
                            <ExpandableText
                                text={properties.failReason}
                                sx={{
                                    fontFamily:
                                        'Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif',
                                }}
                            />
                        )}
                    </Box>
                )}
                {properties.status === ExecutionPodVersionStatus.Ready && (
                    <Box>
                        <Typography variant='body2'>
                            Execution Pod version has been successfully built and is ready to use.
                        </Typography>
                    </Box>
                )}
                {properties.status === ExecutionPodVersionStatus.Archived && (
                    <Box>
                        <Typography variant='body2'>Execution Pod version has been archived.</Typography>
                    </Box>
                )}
            </>
        </NotificationContent>
    )
}
