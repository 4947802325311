import { useMemo } from 'react'
import { apiSlice } from '../../app/apiSlice'
import {
    MetadataField,
    MetadataFieldCategory,
    MetadataFieldDetail,
    MetadataFieldTarget,
    MetadataFieldType,
} from '../../model/model'

export type CreateMetadataFieldRequest = {
    name: string
    index: number
    type: MetadataFieldType
    ontologyId?: number | null
    options?: string[] | null
    isPatientId: boolean
    isSampleId: boolean
    visibleByDefault: boolean
    description: string
    category: MetadataFieldCategory
}

export type UpdateMetadataFieldRequest = CreateMetadataFieldRequest & {
    id: number
}

export const metadataFieldApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMetadataFields: builder.query<MetadataField[], MetadataFieldTarget>({
            query: (target: MetadataFieldTarget) => `/private/metadata-field/list?target=${target}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'MetadataField' as const, id })),
                          { type: 'MetadataField', id: 'LIST' },
                      ]
                    : [{ type: 'MetadataField', id: 'LIST' }],
        }),

        listAdminMetadataFields: builder.query<MetadataFieldDetail[], void>({
            query: () => `/private/metadata-field/admin/list`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'MetadataField' as const, id })),
                          { type: 'MetadataField', id: 'LIST' },
                      ]
                    : [{ type: 'MetadataField', id: 'LIST' }],
        }),

        createMetadataField: builder.mutation<MetadataFieldDetail, CreateMetadataFieldRequest>({
            query: (req) => ({
                url: '/private/metadata-field/admin/create',
                method: 'POST',
                body: req,
            }),
            invalidatesTags: [{ type: 'MetadataField', id: 'LIST' }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data: newField } = await queryFulfilled

                    // Optimistically update admin metadata list
                    dispatch(
                        metadataFieldApiSlice.util.updateQueryData('listAdminMetadataFields', undefined, (draft) => {
                            draft.push(newField)
                        }),
                    )

                    // Optimistically update regular metadata list if applicable
                    if (newField.target) {
                        dispatch(
                            metadataFieldApiSlice.util.updateQueryData(
                                'getMetadataFields',
                                newField.target,
                                (draft) => {
                                    draft.push(newField)
                                },
                            ),
                        )
                    }
                } catch (error) {
                    console.error('Error creating metadata field:', error)
                }
            },
        }),

        updateMetadataField: builder.mutation<MetadataFieldDetail, UpdateMetadataFieldRequest>({
            query: (req) => ({
                url: '/private/metadata-field/admin/update',
                method: 'PUT',
                body: req,
            }),
            invalidatesTags: (result) =>
                result ? [{ type: 'MetadataField', id: result.id }] : [{ type: 'MetadataField', id: 'LIST' }],
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    metadataFieldApiSlice.util.updateQueryData('listAdminMetadataFields', undefined, (draft) => {
                        const field = draft.find((f) => f.id === arg.id)
                        if (field) {
                            Object.assign(field, arg)
                        }
                    }),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error updating metadata field:', error)
                    patchResult.undo()
                }
            },
        }),

        deleteMetadataFields: builder.mutation<number[], number[]>({
            query: (req) => ({
                url: '/private/metadata-field/admin/delete',
                method: 'DELETE',
                body: req,
            }),
            invalidatesTags: (result) =>
                result
                    ? [...result.map((id) => ({ type: 'MetadataField' as const, id }))]
                    : [{ type: 'MetadataField', id: 'LIST' }],
            async onQueryStarted(ids, { dispatch, queryFulfilled }) {
                const adminPatchResult = dispatch(
                    metadataFieldApiSlice.util.updateQueryData('listAdminMetadataFields', undefined, (draft) => {
                        return draft.filter((field) => !ids.includes(field.id))
                    }),
                )

                try {
                    await queryFulfilled
                } catch (error) {
                    console.error('Error deleting metadata fields:', error)
                    adminPatchResult.undo()
                }
            },
        }),
    }),
})

export const {
    useGetMetadataFieldsQuery,
    useListAdminMetadataFieldsQuery,
    useCreateMetadataFieldMutation,
    useUpdateMetadataFieldMutation,
    useDeleteMetadataFieldsMutation,
} = metadataFieldApiSlice

export function useGetMetadataMapQuery(target: MetadataFieldTarget): {
    isLoading: boolean
    isError: boolean
    lookup: Map<string, MetadataField>
    data: MetadataField[] | undefined
} {
    const query = useGetMetadataFieldsQuery(target)

    const metadataMap = useMemo(() => {
        const map = new Map<string, MetadataField>()
        if (query.data) {
            for (const field of query.data) {
                map.set(field.name, field)
            }
        }

        return map
    }, [query.data])

    return {
        isError: query.isError,
        isLoading: query.isLoading,
        data: query.data,
        lookup: metadataMap,
    }
}
