import { wsSlice } from '../../../app/wsSlice'
import { receivedCommand } from './analysisDetailsSlice'

/**
 * Initialize WebSocket handlers for analysis details updates
 */
export function initAnalysisDetailsWebSocketListeners() {
    wsSlice.injectSelectHandlers(
        (m) => m.execCommand,
        [
            (p, dispatch) => {
                dispatch(receivedCommand({ cmd: p }))
            },
        ],
    )
}
